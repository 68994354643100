import React, { Component, useEffect, useState } from "react";
import { FaBars, FaUserAlt } from "react-icons/fa";
import { IoIosArrowDown, IoIosNotificationsOutline, IoMdAdd, IoMdLogOut } from "react-icons/io";
import { BiLogOut } from "react-icons/bi";
 
import { useHistory } from "react-router-dom";
import Dropdown from "rc-dropdown";
import "rc-dropdown/assets/index.css";
 
import Menu, { Item as MenuItem, Divider } from "rc-menu";
import { Link } from "react-router-dom";
import { IconContext } from "react-icons/lib";
import request from "../configs";
import { Avatar, Box, Button, IconButton, ListItemIcon, Tooltip, Typography } from "@mui/material";
import { MdPersonAdd, MdSettings } from "react-icons/md";
import AgencyService from "../services/Agency.services";
import { ga } from "react-ga";
import { AiFillSetting } from "react-icons/ai";
import {AgencySetting} from "../components/AgencySetting";

export default class Header extends Component {
    state = {
    visible: false
  };


    setvisible = (e) => {
    this.setState({visible:false})
  }
 
 
  render() {
   
    return (
      <header>
  
        <div
          className="btn-toggle"
          onClick={() => this.props.handleToggleSidebar(true)}
        >
          <FaBars />
        </div>
     
        <span className="title">{this.props.title}</span> 
        <div style={{display:"flex"}}>
        
        <Button className="addpelgrim" size="large" variant="contained" onClick={   e=>  window.open("/Pelgrims/Addpelgrim/undefined/new","_self") }> 
    
      <span>

      <IoMdAdd/> 
      اضافة معتمر 
       
        </span>  </Button>

        <span>
   
          <HeaderBtn /></span>

          <span className="config"><Agencys></Agencys></span> 

        <span>
     
        <Button   className="logout" size="large" variant="contained" onClick={e=>{localStorage.clear();  window.location.reload(false);}} > <BiLogOut ></BiLogOut></Button>
       
        </span>
     
        </div>
      </header>

      
    );
  }
}

const Agencys = (props) => {
  const [visible, setvisible] = useState(false);

  return (
    
    <div >
    <AgencySetting visible={visible}   setvisible2={ e=>{setvisible(false)}} ></AgencySetting>
    <IconButton><AiFillSetting onClick={e=>setvisible(true)}></AiFillSetting> </IconButton>
    </div>
  )
}


const HeaderBtn = (props) => {
  const [dis, setdis] = useState(false);
  const [Agency, setAgency] = useState([]);
 
  const _agencyService = new AgencyService();
 

 function  updateagancy(x){
   
    localStorage.setItem("Agency", JSON.stringify(x));
    setdis(false);
    window.location.reload(false);
  }
  
  useEffect(() => {

    document.title =JSON.parse(localStorage.getItem("Agency"))!=null?JSON.parse(localStorage.getItem("Agency")).agancyNameAr:"AWS LABS";

    
     
  },[]);

  
   

   

  return (
    
   <div  style={{position:"relative",top:"50px",left:"15px",width:"220px",height:"150px",}}>
   
 <IconButton onClick={e=>setdis(!dis)}>
 
      <Avatar sx={{ width: 32, height: 32 }} src={ JSON.parse(localStorage.getItem("Agency"))!=null?JSON.parse(localStorage.getItem("Agency")).logo:""}> 
      
      {JSON.parse(localStorage.getItem("Agency"))!=null?JSON.parse(localStorage.getItem("Agency")).agancyNameAr[0]:""}
      </Avatar>
 
    </IconButton>
    <span style={{fontSize:"55%" , fontWeight:"bold"}}>
  {JSON.parse(localStorage.getItem("Agency"))!=null?JSON.parse(localStorage.getItem("Agency")).agancyNameAr:""}

  </span>

    <Box sx={{ display: 'flex', alignItems: 'right', textAlign: 'right',backgroundColor:"white" }} >
    
 
 
    </Box>
   </div>

  
       
  );
};
