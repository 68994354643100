import React, { useEffect, useState } from "react";
  
 import AgencyService from "../../services/Agency.services";
 import { arSD, DataGrid } from '@mui/x-data-grid';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, CardActions, CardContent, FormControl, Grid, IconButton, InputBase, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import countrylist from "../../data/country.json"
import embasylist from "../../data/embasy.json"
import scanner from "../../data/scanner.json"
import wakil from "../../data/walik.json"
 
import {
  MdDelete   , Forever,
  MdEdit,
  MdPhotoCamera,
  MdExpandMore
} from "react-icons/md";
import moment from "moment";
import { display } from "@mui/system";
import { BsSearch } from "react-icons/bs";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Modal } from "react-bootstrap";
import { IoMdAdd } from "react-icons/io";
 
 
 
export default function Agency({ ...props }) {
const[Agency,setAgency]=useState([]);
const[user,setuser]=useState({user:"",password:"",mail:""});
const[openadd,setopenadd]=useState(false);


const[agency,setagency]=useState({
  
  adresse:"",
  agancyNameAr:"",
  agancyNameFrr:"",
  city:"",
  country:null,
  createdBy:"",
  logo:"",
  phone:"",
  scanner:1,
  updatedBy:"",
  userId: 0,
  wakil:1,
  wakilUser:"",
  wakilpassword:"",

  updatedDate: null
});
 
 function resetagancy(){
  setagency({
  
    adresse:"",
    agancyNameAr:"",
    agancyNameFrr:"",
    city:"",
    country:null,
    createdBy:"",
    logo:"",
    phone:"",
    scanner:1,
    updatedBy:"",
    userId:JSON.parse(localStorage.getItem("Agency")).userId,
    wakil:1,
    wakilUser:"",
    wakilpassword:"",
  
    updatedDate: null
  })
  setopenadd(false)
 }
const _agencyService = new AgencyService();

function addagency(){
  _agencyService.addagency(agency).then(res=>{
   
    getagency(1);
   console.log(res.data);
   NotificationManager.success('تم الحفظ بنجاح', '');

   localStorage.setItem("Agency", JSON.stringify(agency));
   resetagancy();
  }).catch(res=>{
    NotificationManager.error('يرجى التحقق من البينات', '');
  }

  )
}

function Updateagency(id){
  setagency(Agency.find(x=>x.id==id));
  setopenadd(true);
}

function deleteagency(id){
  if(JSON.parse(localStorage.getItem("Agency")).id!=id){

 
  _agencyService.deleteagency(id).then(res=>{
   
    getagency(1);
    NotificationManager.success('تم الحذف بنجاح', '');
  }).catch(res=>{
    NotificationManager.error('يرجى التحقق من البينات', '');
  }

  )}else{

    NotificationManager.error(' لايمكن حدف الوكالة ','');
  }
}

function getagency(x){
  _agencyService.getagency(x).then(res=>{
    res.data.map( x=>{
      x.createdDate=moment(x.createdDate).format('MM/DD/YYYY');
     
    }
    )
    setAgency(res.data);
  })
 
 
}




const columns = [
  { field: 'logo', headerName: 'شارة ', width: 80, renderCell: (parm) => {
    return (
      <div>
       <img src={parm.row.logo} style={{width:"50px"}}></img>

      </div>
    );
  } },
  { field: 'agancyNameAr', headerName: 'اسم الوكالة العربي', width: 135},
  { field: 'agancyNameFrr', headerName: 'اسم الوكالة الاجنبي', width: 135 },
  { field: 'country', headerName: 'الدولة', width: 80 },
  { field: 'wakil', headerName: ' الوكيل', width: 100 },
  { field: 'scanner', headerName: ' الماسح الضوئي', width: 135 },
  

 
  {
    field: "actions",
    headerName: "العمليات",
    sortable: false,
    width: 115,
    disableClickEventBubbling: true,
    renderCell: (parm) => {
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          <IconButton   className="deleteicon" aria-label="delete" size="large" onClick={e=>deleteagency(parm.id)}>
  <MdDelete    />
</IconButton>
<IconButton aria-label="delete" size="large" onClick={e=>Updateagency(parm.id)}>
  <MdEdit   />
</IconButton>
        </div>
      );
    }
  }
];

function getphotoBase64(file) {
  let document = "";
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
     
    setagency({...agency,logo:reader.result})
     
  };
  reader.onerror = function (error) {
      console.log('Error: ', error);
  };

 
}

 
useEffect(()=>{
   
  getagency(1);
 

},[])
return(
  

 
 
  <div >
 
 
<Card variant="outlined" style={{height:"80vh"}}>
   <CardContent> 
   <div className="searchuser">
      <div style={{width:"80%"}}>
   <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'    }}
    >
    
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="البحث عن الوكالة"
        inputProps={{ 'aria-label': ' البحث عن الوكالة' }}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <BsSearch />
      </IconButton>
     
    </Paper>
   </div>
    <Button className="addpelgrim" size="large" variant="contained" onClick={   e=>  setopenadd(true)}> 
    <span>
      <IoMdAdd/> 
      اضافة وكالة 
       
        </span> 
        </Button>
    </div>
    <br></br>
            <DataGrid 
 density={"comfortable"}
       dir="rtl"
      localeText={arSD.components.MuiDataGrid.defaultProps.localeText} 
style={{height:"60vh"}}
        rows={Agency}
        columns={columns}
        rowsPerPageOptions={[5,10,100,1000]}
        
      />
      </CardContent>
 
</Card>  
  
 
   
 
 
<Modal

 
 show={openadd}
 onHide={e=>setopenadd(false)}
 size="lg"
 aria-labelledby="contained-modal-title-vcenter"
 centered
 dir="RTL"
>
<Grid   container  spacing="10"   >
 <Grid item  xs={6} sm={6} md={6}>

 <span className="tpg"> معلومات الوكالة</span>
<div className="userdiv"> 
   <TextField
 
 required
 label="اسم الوكالة العربية"
 id="filled-basic"
 variant="outlined"
 onChange={e=>{setagency({...agency,agancyNameAr:e.target.value})}}
 value={agency.agancyNameAr}
 />
  <TextField
  
 required
 label=" اسم الوكالة الاجنبي  "
 id="filled-basic"
 variant="outlined"
 value={agency.agancyNameFrr}
 onChange={e=>{setagency({...agency,agancyNameFrr:e.target.value})}}
  
 />

</div>
<div className="userdiv"> 

<TextField
 
 required
 label=" الهاتف  "
 id="filled-basic"
 variant="outlined"
 onChange={e=>{setagency({...agency,phone:e.target.value})}}
 value={agency.phone}
 />
 <TextField
  
 required
 label="العنوان"
 id="filled-basic"
 variant="outlined"
 onChange={e=>{setagency({...agency,adresse:e.target.value})}}
 value={agency.adresse}
 />
</div>
<div className="userdiv">  
<FormControl  >
       <InputLabel id="demo-simple-select-autowidth-label">الدولة  </InputLabel>
       <Select
         labelId="demo-simple-select-autowidth-label"
         id="demo-simple-select-autowidth"
         value={agency.country}
     
         onChange={e=>{setagency({...agency,country:e.target.value})}}
         
         label=" الدولة "
       >
        {
           countrylist.countries.country.map(x=>{
             return (<MenuItem value={x.name_country_ar} >{x.name_country_ar}</MenuItem>)
           })

         }
     
       </Select>
     </FormControl>
  
<FormControl >
       <InputLabel id="demo-simple-select-autowidth-label">القنصلية  </InputLabel>
       <Select
         labelId="demo-simple-select-autowidth-label"
         id="demo-simple-select-autowidth"
         value={agency.city}
     
         onChange={e=>{setagency({...agency,city:e.target.value.toString()})}}
         
         label=" القنصلية  "
       >
          {
        
      
        agency.country!=null?["",embasylist.OFFLINE_EMBASSY.EMBASSY.find(x=>x.phone==countrylist.countries.country.find(x=>x.name_country_ar==agency.country).phone)].map(x=>{
             return (<MenuItem value={x.EMB_NAME_AR}>{x.EMB_NAME_AR}</MenuItem>)
           }):""

         }
       </Select>
     </FormControl>
     </div>
 

     <span className="tpg">معلومات الوكيل</span>
<div className="userdiv"> 

 
     <FormControl >
       <InputLabel id="demo-simple-select-autowidth-label">الوكيل  </InputLabel>
       <Select
         labelId="demo-simple-select-autowidth-label"
         id="demo-simple-select-autowidth"
         value={agency.wakil}
     
         onChange={e=>{setagency({...agency,wakil:e.target.value})}}
         
         label=" الوكيل  "
       >
     {
           wakil.wakil.map(x=>{
             return (<MenuItem value={x.id} >{x.name_ar}</MenuItem>)
           })

         }
       </Select>
     </FormControl>
</div>
<div className="userdiv"> 
 <TextField
  
 required
 label=" اسم المستخدم"
 id="filled-basic"
 variant="outlined"
 onChange={e=>{setagency({...agency,wakilUser:e.target.value})}}
 value={agency.wakilUser}
 />
 <TextField
  
  required
  label="  كلمة المرور  "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setagency({...agency,wakilpassword:e.target.value})}}
  value={agency.wakilpassword}
  />


  
</div>
     
</Grid>
<div class="vl"></div>
<Grid item xs={5.9} sm={5.9} md={5.9}>
 <div style={{height:"25vh" ,display:"flex",justifyContent:"center"}}>
       <IconButton color="primary" aria-label="upload picture" component="label"  >
 <div style={{display:"felx"}}>
<Avatar alt="Remy Sharp" src={agency.logo }style={{width:"100px",height:"100px"}}/>

  <input hidden accept="image/*" type="file"    onChange={e=>{ 
    
    getphotoBase64(e.target.files[0])
 
     }}/>

  </div>
</IconButton> 
</div>
<hr></hr>
<div className="userdiv">
<FormControl >
       <InputLabel id="demo-simple-select-autowidth-label">الماسح الضوئي  </InputLabel>
       <Select
       rows={14}
         labelId="demo-simple-select-autowidth-label"
         id="demo-simple-select-autowidth"
         value={agency.scanner}
     
         onChange={e=>{setagency({...agency,scanner:e.target.value})}}
         
         label=" الماسح الضوئي "
       >
             {
           scanner.scannerlist.map(x=>{
             return (<MenuItem value={x.id} >{x.name_ar}</MenuItem>)
           })

         }
       </Select>
     </FormControl>
</div>



</Grid>

</Grid>
<hr></hr>
<div className="footermodal">
<Button className="save" size="large" variant="contained"  onClick={e=>addagency()}> حفظ</Button>
    
     <Button  className="cancel" size="large" variant="contained"  onClick={e=>resetagancy()}> الغاء</Button>

</div>
</Modal>
    </div>
 
 
)
}
 