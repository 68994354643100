import axios from "axios";
 

// import { authHeader } from "./auth-header";
export default class scanService {
  constructor() {}

  ScanService(){
    
 
      
    // Update the formData object 

       
     }

  add(file){
    
    const formData = new FormData(); 
      
    // Update the formData object 
  
 if(JSON.parse(localStorage.getItem("Agency")).scanner!=0){
  var body={
    scannerId:JSON.parse(localStorage.getItem("Agency")).scanner,
    port:"4040",
    }
       
   
   
   return axios.post("http://localhost:8732/Scan", body,{
    headers: {
      "Connection": "keep-alive",
      "Host": "localhost:8732",
      "Content-Type": "application/json",
    },
    
  });
 }else{
  formData.append( 
    "File", 
    file,
    file.name 
  ); 

  return axios.post("http://scan2.awslabs.tn/api/Scan/", formData,{
    headers: {
      "Content-Type": "multipart/form-data",
    },
    
  });
 }
 

       
     }
  
}
