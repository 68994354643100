import { Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
 
 
import "react-toastify/dist/ReactToastify.css";
import { VisaSelector } from "../../components/VisaSelector";
 
import { WakilSendSelector } from "../../components/WakilSendSelector";
 import PelgrimsService from "../../services/Pelgrims.services";

 
 
export default function VisaSend({ ...props }) {
const[mutamars,setmutamars]=useState([]);


 
 


const _PelgrimsService = new PelgrimsService();

 
useEffect(()=>{
  
 

},[])
return(
  <div>
    <Card variant="outlined"  >
   <CardContent> 
    <VisaSelector mutamars={mutamars} setmutamars={setmutamars}/>

    </CardContent>
    </Card>
    
    </div>
  
)
}
 