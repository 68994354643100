import { FormControl, InputLabel, Select,MenuItem, IconButton, Button, Modal, CardActionArea, CardContent, Card, TextField, CardHeader, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Radio, Avatar, Tooltip } from "@mui/material";
import { Container } from "@mui/system";
import { arSD, DataGrid, GridToolbar } from "@mui/x-data-grid";
import React, { Component, useEffect, useState } from "react";
import { MdBorderStyle, MdDelete   , Forever, MdEdit } from "react-icons/md";
import { IoMdAdd } from "react-icons/io";

import grouppingService from "../services/groupping.services";
import PelgrimsService from "../services/Pelgrims.services";
import ReportingServices from "../services/reporting.services";
 
import{
  IoIosPeople
}from "react-icons/io";
import{
  FaCcVisa
}from "react-icons/fa";
import{
  BsFillPersonCheckFill
}from "react-icons/bs";
import { useHistory } from "react-router-dom";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import portofentry from "../data/portofentry.json";
import transport from "../data/transport.json";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Checkbox } from "@fluentui/react";
import { Status } from "./status";
import { AiFillPrinter } from "react-icons/ai";
import { Print } from "./Print";

  export const   GroupMuatmasselector =({ ...props }) => {
    const [filter, setfilter] = useState (1);
    const [textofind, settextofind] = useState ();
    const [groupid, setgroupid] = useState ();
    const [filterrapport, setfilterrapport] = useState ();
    const [selected, setselected] = useState ([]);
    const [disabled, setdisbaled] = useState (false);
    
    const [loadingdata, setloadingdata] = useState (false);
    const [traveldata, settraveldata] = useState (
      {flightNumber:"",
      transport:"جو",
      stayPeriod:24,
      dateArrival:new Date(),
      wayPort:"المدينه"});
    const [groups, setgroups] = useState ([]);
    const [mutamars, setmutamars] = useState ([]);
    const [mutamarsfiltred, setmutamarsfiltred] = useState ([]);
    const [printview,setprintview ]=useState (false);
    const [travelinfoview,settravelinfoview ]=useState (false);
    const [selectedValue, setSelectedValue] = useState (0);

    function handleChangefilter(e)   {
  
      setSelectedValue(e);
      if(e==0){
        setmutamarsfiltred( mutamars);
      }
      if(e==1){
        setmutamarsfiltred(  mutamars.filter(x=>x.fields.visaNumber!=null) );
      }
    
      if(e==2){
        setmutamarsfiltred(   mutamars.filter(x=>x.fields.wakilNumber!=null));
      }
      if(e==3){
        setmutamarsfiltred(  mutamars.filter(x=>x.fields.visaNumber==null) );
      }
    
      if(e==4){
        setmutamarsfiltred(   mutamars.filter(x=>x.fields.wakilNumber==null));
      }
      
      
    };
    const[badgeparm,setbadgeparm]=useState({
      MakkaHotel:"MakkaHotel",
    MedinaHotel:"MakkaHotel",
    ChefGroupe:"MakkaHotel",
    Datego:"MakkaHotel"} );
    const history = useHistory();
    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: '2px solid #000',
      boxShadow: 24,
      pt: 2,
      px: 4,
      pb: 3,
    };


    const [open, setOpen] =useState(false);
    const [idtoremove, setidtoremove] =useState(null);

    function handleClickOpen (x)  {
      setidtoremove(x);
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
    const _grouppingService = new grouppingService();
    const _pelgrimsService = new PelgrimsService();
    const _reportingServices = new ReportingServices();
      
     
    function archive(){
       
      
      const  body=traveldata;
 
       body.mutamrsid=selected;
       _pelgrimsService.archive(body).then(res=>{
         NotificationManager.success('تم الحفظ '+res.data+'بنجاح', '');
       
       }).catch(error=>{
         NotificationManager.error('يرجى التحقق من المعلومات', '');
  
       })
      
     }
    function printrapport(){
       
     
      let body={

    rapporttype: filterrapport+"" ,
     
      parms:
      {
        "MakkaHotel":badgeparm.MakkaHotel,
      "MedinaHotel":badgeparm.MedinaHotel,
      "ChefGroupe":badgeparm.ChefGroupe,
      "Datego":badgeparm.Datego} 
    ,
    mutamarsList:selected 
    }
      _reportingServices.getrapport(body).then(res=>{
     
    
        var b = new Blob([res.data],{encoding:"UTF-8",type:"application/pdf;charset=UTF-8"});
        var url = URL.createObjectURL(b);
        window.open(url,"_blank","");
     
      })

     
    }
    function sendtraveldata(){
       
      
     const  body=traveldata;

      
      selected.forEach(mutamrsid => {
        _pelgrimsService.Updatetraveldata(body,mutamrsid).then(res=>{
          NotificationManager.success('تم الحفظ  بنجاح', '');
        
        }).catch(error=>{
          NotificationManager.error('يرجى التحقق من المعلومات', '');
   
        })
      });
     
     
    }
    function startprint(){
      if(filterrapport==1){
        setprintview(true);
       
      }else{
        printrapport();
      }
    

    }
    function getgroups(x){
        _grouppingService.getgroups(x).then(res=>{
        
          setgroups(res.data.records);
      
        }).catch(res=>{
          NotificationManager.error('يرجى التحقق من البينات', '');
        }

        )
    }
    function findbytext(x){
      settextofind(x);
if(x.length>3){

  setmutamars([]);
  setmutamarsfiltred([]);
       _pelgrimsService.getbytext(x).then(res=>{
        setmutamars([]);
        setmutamarsfiltred([]);
    res.data.records.map(x2=>{
      setmutamars((prevArray) => [...prevArray,  x2]);
      setmutamarsfiltred((prevArray) => [...prevArray,  x2]);
    })
  
       }
   
       )
      }  
     
    }

    function getpelgrims(x,i){
     
         setmutamars([]);
      setmutamarsfiltred([]);
  
     
          _pelgrimsService.GetByGroupNamepic(groups[x].fields.Mutamrs[i]).then(res=>{
           setmutamars((prevArray) => [...prevArray,  res.data]);
           setmutamarsfiltred((prevArray) => [...prevArray,  res.data]);
          if(groups[x].fields.Mutamrs.length> i+1){getpelgrims( x,i+1)}else{
            setloadingdata(false);
            setdisbaled(false);
          }
          });
 
        
    
         
     

       
    }
    function getpelgrims2(x){
       
     
  setmutamars([]);
  setmutamarsfiltred([]);
   
       _pelgrimsService.getmutbygroup(groups[x].fields.ID).then(res=>{
        setmutamars([]);
        setmutamarsfiltred([]);
    res.data.records.map(x2=>{
      setmutamars((prevArray) => [...prevArray,  x2]);
      setmutamarsfiltred((prevArray) => [...prevArray,  x2]);
      setloadingdata(false);
      setdisbaled(false);
    })
  
       }
   
       )
        

       
    }
    function Delete(){
      _pelgrimsService.Delete(idtoremove).then(res=>{
        setloadingdata(true);
        setdisbaled(true);
        getpelgrims2(groupid)
        NotificationManager.success('تم الحذف بنجاح', '');
        setidtoremove(null);
        setOpen(false);
       
    }).catch(res=>{
      NotificationManager.error('يرجى التحقق من البينات', '');
      setOpen(false);
    }

    )
    }
    
    const handleChange2 = (event) => {
         
        setgroupid(event.target.value);
        setmutamars([]);
        setmutamarsfiltred([]);
        if(groups[event.target.value].fields.Mutamrs!== undefined){
          getpelgrims2(event.target.value)
          setloadingdata(true);
          setdisbaled(true);
        } 
    
        
    };
    const handleChange = (event) => {
        
        setfilter(event.target.value);
    };

    const columns = [
     
        { field: 'arabicName ', headerName: 'الإسم الكامل ', width: 250, renderCell: (parm) => {
          return (
            <div style={{display:"flex"}}>
            
            <Avatar sx={{ width: 50, height: 50 }}  src={parm.row.fields.pilgrimPicturePath!=null?parm.row.fields.pilgrimPicturePath[0].thumbnails.small.url:""}  ></Avatar>
        
         
            <div style={{display:"flex",flexDirection:"column" ,marginTop:"5px"}}>
              <span className="namestyle">
                {parm.row.fields.arabicName+" "+ parm.row.fields.arabicLastName}
                </span>
<span className="namestyle">
{parm.row.fields.firstName+" "+ parm.row.fields.lastName}
</span>
            </div>
            </div>
          )} },
        
   
        
        { field: 'passportNumber', headerName: 'رقم الجواز', width: 120  ,valueGetter: params => params.row.fields.passportNumber },
        { field: 'nationalityCountry', headerName: ' الجنسية', width: 100 , valueGetter: params => params.row.fields.nationalityCountry },
        { field: 'wakilNumber', headerName: ' رقم الطلب', width: 110 , valueGetter: params => params.row.fields.wakilNumber },
        { field: 'visaNumber', headerName: ' رقم التأشيرة', width: 110 , valueGetter: params => params.row.fields.visaNumber },
        
        { field: 'notes', headerName: 'نتيجة الارسال', width: 250 ,valueGetter: params => params.row.fields.notes },
       
        {
          field: "actions",
          headerName: "العمليات",
          sortable: false,
          width: 140,
          disableClickEventBubbling: true,
          renderCell: (parm) => {
            return (
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ cursor: "pointer" }}
              >
                <IconButton className="deleteicon" aria-label="addtolist" size="large" onClick={e=>handleClickOpen(parm.row)}>
        <MdDelete    />
      </IconButton>
      <IconButton aria-label="addtolist" size="large"     onClick={  e=>  window.open("/Pelgrims/Addpelgrim/"+parm.row.fields.groupingId+"/"+parm.id, "_blank")}  >
        <MdEdit   />
      </IconButton>
  
              </div>
            );
          }
        }
      ];
  useEffect(() => {
    getgroups(groupid);
  },[])
  return(
    <div>
      
  
<Modal
    
    open={printview}
    onClose={e=>setprintview(false)}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
   >
   <Card style={{position:"absolute",left:"40%",top:"10%",width:"400px"}}>
    <CardContent dir="rtl">
    
    <div className="tpg" style={{margin:"20px"}} >
      معلومات التقرير
            </div>
            <div style={{padding:"15px"}}>
    <TextField
    style={{width:"100%" ,marginTop:"10px"}}
    required
    label="النزل بمكة"
    id="filled-basic"
    variant="outlined"
    onChange={e=>{setbadgeparm({...badgeparm,MakkaHotel:e.target.value});}}
    value={badgeparm.MakkaHotel}
    dir="rtl"
    />
      <TextField
    style={{width:"100%" ,marginTop:"10px"}}
    required
    label="النزل بالمدينة"
    id="filled-basic"
    variant="outlined"
    onChange={e=>{setbadgeparm({...badgeparm,MedinaHotel:e.target.value});}}
    value={badgeparm.MedinaHotel}
    dir="rtl"
    />
        <TextField
    style={{width:"100%" ,marginTop:"10px"}}
    required
    label=" رئيس المجموعة"
    id="filled-basic"
    variant="outlined"
    onChange={e=>{setbadgeparm({...badgeparm,ChefGroupe:e.target.value});}}
    value={badgeparm.ChefGroupe}
    dir="rtl"
    />
         <TextField
         dir="rtl"
    style={{width:"100%" ,marginTop:"10px"}}
    required
    label=" تاريخ الذهاب و الاياب"
    id="filled-basic"
    variant="outlined"
    onChange={e=>{setbadgeparm({...badgeparm,Datego:e.target.value});}}
    value={badgeparm.Datego}
   
    />
    </div>
    <Button style={{ height:"50px" ,margin:"10px" }} size="large" variant="contained" onClick={e=>{printrapport(); }} >طباعة </Button>
   
    </CardContent>
   
   
    
   </Card>
  </Modal>
<Modal
    
  open={travelinfoview}
  onClose={e=>settravelinfoview(false)}
  aria-labelledby="parent-modal-title"
  aria-describedby="parent-modal-description"
 >
 <Card style={{position:"absolute",left:"40%",top:"10%",width:"400px"}}>
  <CardContent style={{margin:"20px"}}>
   
  <div className="tpg" style={{margin:"20px"}} >
  معلومات الرحلة  
            </div>
            <div   style={{ width:"100%"}}>
          <LocalizationProvider dateAdapter={AdapterDateFns}  style={{marginTop:"10px" ,width:"100%"}} >
  <DatePicker  
   label="تاريخ الوصول"
    value={traveldata.dateArrival}
    onChange={(e)=>{settraveldata({...traveldata,dateArrival:e})}}
    renderInput={(params) => <TextField {...params} />}
  />
  </LocalizationProvider>
  </div>
  <div   style={{ width:"100%"}}>
  <TextField
   style={{marginTop:"10px" ,width:"100%"}}
   
  label=" مدة إقامتك المتوقعة بالأيام"
  id="filled-basic"
  variant="outlined"
  onChange={e=>{settraveldata({...traveldata,stayPeriod:e.target.value})}}
  value={traveldata.stayPeriod}
  /> 
  </div>
<FormControl   style={{width:"100%",marginTop:"10px"}}>
        <InputLabel id="demo-simple-select-autowidth-label">منفذ الوصول</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={traveldata.wayPort}
          onChange={e=>{settraveldata({...traveldata,wayPort:e.target.value})}}
   
          label="منفذ الوصول  "
        >
      
          {
           portofentry.portOfEntryMap.map(x=>{
              return (<MenuItem value={x.name_ar}>{x.name_ar}</MenuItem>)
            })
 
          }
        
     
        </Select>
     
 </FormControl>
 <div>
      <FormControl   style={{width:"100%",marginTop:"10px"}}>
        <InputLabel id="demo-simple-select-autowidth-label">   طريقة الوصول  </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={traveldata.transport}
          onChange={e=>{settraveldata({...traveldata,transport:e.target.value})}}
   
          label=" طريقة الوصول  "
        >
      
          {
           transport.transport.map(x=>{
              return (<MenuItem value={x.name_ar}>{x.name_ar}</MenuItem>)
            })
 
          }
        
     
        </Select>
 </FormControl>
 </div>
 <div style={{width:"100"}}>
 <TextField 
   style={{marginTop:"10px" ,width:"100%"}}
   
  label="رقم الرحلة "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{settraveldata({...traveldata,flightNumber:e.target.value})}}
  value={traveldata.flightNumber}
  /> 
  </div>
  <Button style={{ height:"50px" ,margin:"10px" }} size="large" variant="contained" onClick={e=>{sendtraveldata();settravelinfoview(false); }} >حفظ </Button>
 
  </CardContent>
  
 </Card>
</Modal>
    <div>
 <FormControl sx={{ m: 1, width: 250 }}>
        <InputLabel id="demo-simple-select-autowidth-label">بحث حسب  </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={filter}
      
          onChange={handleChange}
          
          label="بحث حسب"
        >
          <MenuItem value="">
            <em>  </em>
          </MenuItem>
          <MenuItem value={1}>بحث حسب المجموعة</MenuItem>
          <MenuItem value={2}>بحث حسب الاسم او رقم الجواز</MenuItem>
      
        </Select>
      </FormControl>
      
      {filter==1?
     <FormControl sx={{ m: 1, width: 400 }} dir="RTL">
      <InputLabel id="demo-simple-select-autowidth-label"> المجموعة  </InputLabel>
      <Select dir="RTL"
     
        labelId="demo-simple-select-autowidth-label"
        id="demo-simple-select-autowidth"
        value={groupid}
        onChange={handleChange2}
        label=" اسم المجموعة"
        disabled={disabled}
      >
         
        {
          groups.map( (x,index)=>{
              return <MenuItem value={index}>
                <div dir="RTL"> 
<table style={{fontSize:"14px"}}>
  <tr>
  <td style={{fontSize:"20px"}}>  {x.fields.name+" "}   </td>

  <td><Status  data={{"total":x.fields.totalmutamr,"visa":x.fields.totalvisa,"mouwaf9a":x.fields.totalmouwaf9a}}/> </td>
 
  
  </tr>
</table>


</div>
                
              
                
                </MenuItem>


          })
        }

      </Select>
      
      </FormControl> 
      
      
      
      :
      
      
      <FormControl sx={{ m: 1, width: 400 }}>
      <TextField
      style={{ }}
      
     label="رقم الجواز "
     id="filled-basic"
     variant="outlined"
     onChange={e=>{findbytext(e.target.value)}}
     value={textofind}
     /> 
     
     </FormControl>
     } 
  
     
      <FormControl>
  
      
      </FormControl>
    </div>
    <div>
    <Container fixed>
                  <DataGrid 
 density={"comfortable"}
     
       dir="rtl"
      localeText={arSD.components.MuiDataGrid.defaultProps.localeText} 
style={{height:"60vh"}}
        rows={mutamarsfiltred}
        columns={columns}
        //pageSize={5}
         rowsPerPageOptions={[5,10,100,1000]}
         pagination
         onSelectionModelChange={itm => setselected(itm)}
        checkboxSelection
        loading={ loadingdata}
      />
      
     </Container>
  
    </div>
    <div style={{display:"flex",fontSize:"12px",justifyContent:"center"}}>  
      <span>الكل<Radio name="filter" checked={selectedValue === 0}  onChange={e=>handleChangefilter(0)} ></Radio></span>
      <span>المتحصلين علي التأشيرة<Radio name="filter" checked={selectedValue === 1}  onChange={e=>handleChangefilter(1)}></Radio></span>
      <span>المتحصلين علي الموافقة<Radio name="filter" checked={selectedValue === 2}  onChange={e=>handleChangefilter(2)}></Radio></span>
      <span> الغير متحصلين علي التأشيرة <Radio name="filter" checked={selectedValue === 3}  onChange={e=>handleChangefilter(3)}></Radio></span>
      <span>الغير  متحصلين علي الموافقة<Radio name="filter" checked={selectedValue === 4}  onChange={e=>handleChangefilter(4)}></Radio></span>
    </div>
    <hr></hr>
    <div style={{ paddingLeft:"5px",display:"flex" ,justifyContent:"flex-start",alignItems:"center"}}>
   
 
<div className="v3"></div> 
    <Button className="printbutton" size="large" variant="contained" onClick={e=>{settravelinfoview(true) }} > معلومات الرحلة</Button>
 
              </div>

              <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" هل انت متأكد من حذف المعتمر؟"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>الغاء</Button>
          <Button onClick={e=>{Delete();  } } autoFocus>
            نعم
          </Button>
        </DialogActions>
      </Dialog>
    </div>

     
    
  )
  };
