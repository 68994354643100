import { IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FaFileExcel, FaFilePdf, FaFileWord, FaRegFilePdf } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const DropdownButton = ({ onSelect }) => {
 
  const [selectedOption, setSelectedOption] = useState("PDF");
 

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    onSelect(option); // Call the onSelect callback with the selected option
 
  };

 
  return (
    <div style={{width:"80%",alignItems:"center",justifyContent:"space-evenly", display:"flex" ,height: "74px"  ,position:"relative"} }>
      <div className="tpg">نوع الملف</div>
   <div style={{ justifyContent:"space-between", display:"flex" ,    width: "180px"  } }>
<IconButton  style={selectedOption=="WORDOPENXML"?{border: "1px solid #5a5a5a78", backgroundColor:"#0065af" ,color:"white",fontSize:"30px"}:{border: "1px solid #5a5a5a78", backgroundColor:"white" ,color:"#5a6b7c"}}onClick={()=>handleOptionSelect('WORDOPENXML')}>
<FaFileWord></FaFileWord>
</IconButton>
<IconButton  style={selectedOption=="PDF"?{border: "1px solid #5a5a5a78", backgroundColor:"#0065af" ,color:"white",fontSize:"30px"}:{border: "1px solid #5a5a5a78", backgroundColor:"white" ,color:"#5a6b7c"}} onClick={()=>handleOptionSelect('PDF')}>
<FaRegFilePdf/>
</IconButton>
<IconButton style={selectedOption=="EXCELOPENXML"?{border: "1px solid #5a5a5a78", backgroundColor:"#0065af" ,color:"white",fontSize:"30px"}:{border: "1px solid #5a5a5a78", backgroundColor:"white" ,color:"#5a6b7c"}}  onClick={()=>handleOptionSelect('EXCELOPENXML')}>
<FaFileExcel/>
</IconButton>
</div> </div> 
       
 
     
  );
};

export default DropdownButton;
