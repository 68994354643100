import { Accordion, AccordionDetails, AccordionSummary, Avatar, Button, Card, CardContent, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import { display } from "@mui/system";
import React, { useEffect, useState } from "react";
import { MdExpandMore } from "react-icons/md";
 
 
import "react-toastify/dist/ReactToastify.css";

import { PelgrimModel } from "../../model/PelgrimModel";
import PelgrimsService from "../../services/Pelgrims.services";
import scanService from "../../services/scan.Service";
import countrylist from "../../data/country.json"
 import status from "../../data/status.json"
 import education from "../../data/education.json"
 import health from "../../data/healthStatus.json"
 import portofentry from "../../data/portofentry.json"
 import transport from "../../data/transport.json"
 import taskin from "../../data/taskin.json"
 import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
 import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
 import { DatePicker } from '@mui/x-date-pickers/DatePicker';
 import Pelgrims from "./Pelgrims";
 import moment from "moment";
import { useHistory } from "react-router-dom";
import grouppingService from "../../services/groupping.services";
import NotificationManager from "react-notifications/lib/NotificationManager";
 
import loadingicon from "../../components/loading.gif"
import { IoMdAddCircle } from "react-icons/io";
 
 
export default function Addpelgrim({ ...props }) {
  const[mrz,setmrz]=useState("");
  const[passportfile,setpassportfile]=useState(null);
  const[resfile,setresfile]=useState(null);
  const[pelgrimfile,setpelgrimfile]=useState(null);
  const[load,setload]=useState(false);
  const [groups, setgroups] = useState ([]);
  const [groupename, setgroupename] = useState ("");
  const [groupemodal, setgroupemodal] = useState (false);
  const [infomodal, setinfomodal] = useState (false);
  
  const [groupingId, setgroupingId] = useState ([]);
  const [checkerror, setcheckerror] = useState (false);
  const [error, seterror] = useState (false);
 const[pelgrimid,setpelgrimid]=useState("");
  const history = useHistory();
const[Pelgrim,setPelgrim]=useState(
  {
    
    title:"",
    gender:"",
    firstName:"",
    lastName:"",
    fatherName:"",
    grandFatherName:"",
    arabicName:"",
    arabicFatherName:"",
    arabicGrandfatherName:"",
    arabicLastName:"",
    mrz:"",
    passportNumber:"",
    passportDelieveryDate:null,
    passportExpiryDate:null,
    passportDeliveryCity:"",
    passportType:"",
    passportDelieveryCity:JSON.parse(localStorage.getItem("Agency")).country,
    passportDeliveryCountry:"",
    nationalityCountry:"",
    cin:"",
    birthDate:null,
    birthCountry:"",
    birthCity:JSON.parse(localStorage.getItem("Agency")).country,
    job:"أخرى",
    civilState:"أخرى",
    educationLevel:"أخرى",
    healthStatus:"سليم",
    healthDescription:"",
  
    addressCity:"",
    tel:"",
    addressZipCode:"",
    addressStreet:"",
    email:"",
    transport:"جو",
    wayPort:"المدينه",
    dateArrival:new Date(),
    stayPeriod:24,
    flightNumber:"tu713",
    destination:"",
    pilgrimPicturePath:null,
    passportPicturePath:null,
    notes:"",
    
   
    visapicPath:"",
    visaNumber:"",
    wakilNumber:"",
     
    createdDate:new Date(),
    createdBy:"",
    updatedDate:new Date(),
    updatedBy:"",
    moqumNumber:"",
  }
);
function addgroupe(){
  if(groupename.replace(/\s/g,"") == ""){

    NotificationManager.error(' اسم المجموعة لايمكن ان يكون فارغ', '');

  }else{
  var gg={name:groupename,id:""};
 
  _grouppingService.addgroup(gg).then(res=>{
     
    getgroups();
 
      NotificationManager.success('تم اضافة بنجاح', '');
   

   console.log(res.data);
  }).catch(res=>{
    NotificationManager.error('يرجى التحقق من البينات', '');
  }

  )
}
}
 
function refreshPage() {
  window.location.reload(false);
}
function _scanmrz() {
 
   setload(true);



  scan
    .add(passportfile)
    .then((res) => {
      
    
      
    
      if(res.data.mrz.length>=88){
 
         
        Pelgrim.arabicName=res.data.arfirstName;
        Pelgrim.arabicLastName=res.data.arlastName;
        Pelgrim.mrz=res.data.mrz;
        Pelgrim.firstName= res.data.firstName;
        Pelgrim.passportDeliveryCountry= countrylist.countries.country.find(x=>x.code==res.data.birthCountryId).name_country_ar;
        Pelgrim.nationalityCountry= countrylist.countries.country.find(x=>x.code==res.data.nationalityCountryId).name_country_ar;
        Pelgrim.birthCountry= countrylist.countries.country.find(x=>x.code==res.data.birthCountryId).name_country_ar;
        Pelgrim.gender=res.data.gender==1?"ذكر":"أنثى";
    
        Pelgrim.lastName= res.data.lastName;
        if( res.data.pilgrimPicture !=null){

          _PelgrimsService.uploadFileToServeur(res.data.pilgrimPicture).then(res3=>{
     
            Pelgrim.pilgrimPicturePath=res3.data.image_url;
      
            })
          
        }
        if( res.data.passportPicture!=null){
          
       
          _PelgrimsService.uploadFileToServeur(res.data.passportPicture).then(res2=>{
     
            Pelgrim.passportPicturePath=res2.data.image_url;
      

           
            })
          
        }
     
         Pelgrim.age= moment(res.data.birthDate,"YYYY-MM-DD").fromNow().split(" ")[0] ;
         Pelgrim.birthDate= moment(res.data.birthDate,"YYYY-MM-DD") ;
         Pelgrim.passportExpiryDate= moment(res.data.passportExpiryDate,"YYYY-MM-DD") ;
         Pelgrim.passportDelieveryDate= moment(res.data.passportExpiryDate,"YYYY-MM-DD").subtract(5, 'years')
       
        Pelgrim.passportNumber= res.data.passportNumber;
        setPelgrim(Pelgrim);
        setinfomodal(true);
      }
      setload(false);
    }).catch(error=>{
      NotificationManager.error('الرجاء تشغل برنامج الماسح الضوئي او الاتصال بدعم الفني', '');
      setload(false); 
    })
  
}

const _PelgrimsService = new PelgrimsService();
const scan=new scanService();
function validateform(){
    
  if(Pelgrim.mrz.length<88 ||
    Pelgrim.arabicName.length<2 ||
    Pelgrim.arabicLastName.length<2||
    Pelgrim.firstName.length<2||
    Pelgrim.lastName.length<2 ||
    Pelgrim.passportNumber.length<2 ||
    Pelgrim.passportDelieveryCity.length<2 ||
    Pelgrim.passportDeliveryCountry.length<2 ||
    Pelgrim.birthCountry.length<2 ||
    Pelgrim.birthCity.length<2 ||
    Pelgrim.birthDate.length<2 ||
    Pelgrim.civilState.length<2 ||
    Pelgrim.educationLevel.length<2 ||
    Pelgrim.job.length<2 ||
    Pelgrim.nationalityCountry.length<2 ||
    Pelgrim.healthStatus.length<2 ||
    Pelgrim.gender.length<2
    )
  
  
  {return true
  }else{
    return false
  }

 
}
 function addMuatamr(){

  history.push("/Pelgrims/Addpelgrim/"+groupingId+"/new");
  if(validateform()){
    NotificationManager.error('يرجى التحقق من البينات', '');
   }else{
 
  
  Pelgrim.groupingId=groupingId;
 

    _PelgrimsService.addMuatamr(Pelgrim,pelgrimid).then(res=>{
    
      NotificationManager.success('تم الحفظ بنجاح', '');
    
      refreshPage();
  
  
  }).catch(error=>{
     
    if(typeof error.response=="undefined"){
      NotificationManager.error('الرجاء الاتصال بدعم الفني', '');
  
      
    }else
    if(error.response.data=="Mutamar exist"){  NotificationManager.warning('المعتمر موجود في مجموعة اخري  ', '');}
  else{   NotificationManager.error('يرجى التحقق من البينات', '');}
  })
  
 
 }
}

 function getresBase64(file) {
  let document = "";
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    _PelgrimsService.uploadFileToServeur(reader.result).then(res=>{
     
    setPelgrim({...Pelgrim,respicturepath:res.data.image_url})
    })
  };
  reader.onerror = function (error) {
      console.log('Error: ', error);
  };

 
}

 function getpassportBase64(file) {
  let document = "";
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    _PelgrimsService.uploadFileToServeur(reader.result).then(res=>{
     
    setPelgrim({...Pelgrim,passportPicturePath:res.data.image_url})
    })
     
  };
  reader.onerror = function (error) {
      console.log('Error: ', error);
  };

 
}
function getphotoBase64(file) {
  let document = "";
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    _PelgrimsService.uploadFileToServeur(reader.result).then(res=>{
     
      setPelgrim({...Pelgrim,pilgrimPicturePath:res.data.image_url})
      })
   
     
  };
  reader.onerror = function (error) {
      console.log('Error: ', error);
  };

 
}
function checkdata(){
  seterror(true);
  return true;
}
function getPelgrims(x){
   
  _PelgrimsService.GetByGroupNamepic(x).then(res=>{
    res.data.age=moment(res.data.birthDate,"YYYY-MM-DD").fromNow().split(" ")[0];
    setPelgrim(res.data.fields);

   
  })
 
 
}
 
const _grouppingService = new grouppingService();
 
function getgroups(x){
    _grouppingService.getgroups(x).then(res=>{
    
      setgroups(res.data.records);
    })
}

 
useEffect(()=>{
  getgroups();
   
  if(props.match.params.id!="new"){
    setpelgrimid(props.match.params.id);
    getPelgrims(props.match.params.id)
  }
  if(props.match.params.Groupid!="new"){
     
    setgroupingId(props.match.params.Groupid);
    
 
  } else{
    setgroupingId(0);
  }
 



} ,[])
return(
  load? (
  <div style={{display:"flex", justifyContent:"center",    alignItems: "center",height:"100vh",backgroundColor:"white"}}>
<div>
<img src={loadingicon} style={{height:"200px"}}></img>
<center>جاري تحميل البيانات من الجواز</center>
</div>
  </div>

  )
  :
  <div>
    <Card variant="outlined"  >
   <CardContent> 
    <div>
 
 
    <Button color="secondary"   component="label"  style={{float:"left",width:"25%"}}>
    <img style={{float:"left",height:"250px",marginLeft:"100px"}}src={Pelgrim.passportPicturePath ==null?"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-VqdQ6_tW0Uw7J43-9bv8wa3zmdtxbWMMcA&usqp=CAU":Pelgrim.passportPicturePath[0].url?Pelgrim.passportPicturePath[0].url:Pelgrim.passportPicturePath} />
        <input hidden accept="image/*" multiple type="file" onChange={e=>{  
          getpassportBase64(e.target.files[0])
           
          setpassportfile(e.target.files[0]); 
     }}/>
      </Button>
   <Button     component="label" style={{float:"right",width:"130px"}}>
   <input hidden accept="image/*" type="file"    onChange={e=>{ 
     
     getphotoBase64(e.target.files[0])
  
      }}/>
   <img style={{width:"130px"}} src={Pelgrim.pilgrimPicturePath ==null?"https://upload.wikimedia.org/wikipedia/commons/thumb/4/4b/User-Pict-Profil.svg/1365px-User-Pict-Profil.svg.png":Pelgrim.pilgrimPicturePath[0].url?Pelgrim.pilgrimPicturePath[0].url:Pelgrim.pilgrimPicturePath} />
   </Button >


  
<Grid   container spacing={2}>
<Grid item   style={{width:"100%",display:"flex"}}  > 
<FormControl sx={{ m: 1, width: 200 }}>
        <InputLabel id="demo-simple-select-autowidth-label"> المجموعة  </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={groupingId}
      
          onChange={e=>{setgroupingId(e.target.value);setmrz(e)}}
           
          label=" اسم المجموعة"
        >
       
          {
            groups.map( x=>{
                return <MenuItem value={x.id}>{x.fields.name}</MenuItem>


            })
          }
 
        </Select>
      </FormControl>
 
      <Button style={{fontSize:"35px"}} onClick={e=>{setgroupename("");setgroupemodal(true)}}><IoMdAddCircle></IoMdAddCircle></Button>
    </Grid>
    <div>
    <Modal
    
    open={groupemodal}
    onClose={e=>setgroupemodal(false)}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
   >
   <Card style={{position:"absolute",left:"40%",top:"10%",width:"400px" }}>
    <CardContent dir="rtl">
    
    <div className="tpg" style={{margin:"20px"}} >
      اضافة مجموعة
            </div>
            <div style={{padding:"15px"}}>
    <TextField
    style={{width:"100%" ,marginTop:"10px"}}
    required
    label=" اسم المجموعة"
    id="filled-basic"
    variant="outlined"
    onChange={e=>{setgroupename(e.target.value);}}
    value={groupename}
    dir="rtl"
    />
   </div>
  
    <Button style={{ height:"50px" ,margin:"10px" }} size="large" variant="contained" onClick={e=>{addgroupe(); }} >حفظ </Button>
   
    </CardContent>
   
   
    
   </Card>
  </Modal>
  <Modal
    
    open={infomodal}
    onClose={e=>setinfomodal(false)}
    aria-labelledby="parent-modal-title"
    aria-describedby="parent-modal-description"
   >
   <Card style={{position:"absolute",left:"40%",top:"10%",width:"400px" }}>
    <CardContent dir="rtl">
    
    <div className="tpg" style={{margin:"20px"}} >
       المعلومات الضرورية
            </div>
            <div style={{padding:"15px"}}>
            <TextField style={{width:"100%",paddingBottom:"10px"}}
  
  required
  label=" الاسم  "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,arabicName:e.target.value})}}
  value={Pelgrim.arabicName}
  error={Pelgrim.arabicName.length<1?e=>checkdata():false}
  />
      <TextField style={{width:"100%",paddingBottom:"10px"}}
  
  required
  label="اللقب   "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,arabicLastName:e.target.value})}}
  value={Pelgrim.arabicLastName}
  error={Pelgrim.arabicLastName.length<1?e=>checkdata():false}
  />
            <TextField style={{width:"100%",paddingBottom:"10px"}}
  
  required
  label="  المدينة الميلاد"
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,birthCity:e.target.value})}}
  value={Pelgrim.birthCity}
  error={Pelgrim.birthCity<1?e=>checkdata():false}
  />
  <TextField style={{width:"100%",paddingBottom:"10px"}}
  
  required
  label="   مدينة اصدار الجواز "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,passportDelieveryCity:e.target.value})}}
  value={Pelgrim.passportDelieveryCity}
  error={Pelgrim.passportDelieveryCity<4?e=>checkdata():false}
  
                 
  />


  </div>
 
    </CardContent>
   
   
    
   </Card>
  </Modal>
  </div>
 <Grid item c>
 
   <Button style={{ height:"54px",marginTop:"10px"}} size="large" variant="contained"  onClick={e=>_scanmrz()} >  قراءة الجواز اليا  </Button>
 
  </Grid>
  <Grid item   style={{width:"80%"}}  > 
    <TextField
  style={{width:"100%" ,marginTop:"10px"}}
  required
  label="MRZ"
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,mrz:e.target.value});setmrz(e.target.value)}}
  value={Pelgrim.mrz}
  error={Pelgrim.mrz.length<88?e=>checkdata():false}
  />
 </Grid></Grid>
<div style={{marginBottom:"20px"}}></div>
  <Grid   container spacing={2}>
 
  
 <Grid item>
    <TextField
  
  required
  label=" الاسم  "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,arabicName:e.target.value})}}
  value={Pelgrim.arabicName}
  error={Pelgrim.arabicName.length<1?e=>checkdata():false}
  />
   </Grid>
  <Grid item>
    <TextField
  
  required
  label=" اسم الاب "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,arabicFatherName:e.target.value})}}
  value={Pelgrim.arabicFatherName}

  />
   </Grid>
  <Grid item>
    <TextField
  
  required
  label="اسم الجد  "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,arabicGrandfatherName:e.target.value})}}
  value={Pelgrim.arabicGrandfatherName}
  />
   </Grid>
    <Grid item>
    <TextField
  
  required
  label="اللقب   "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,arabicLastName:e.target.value})}}
  value={Pelgrim.arabicLastName}
  error={Pelgrim.arabicLastName.length<1?e=>checkdata():false}
  />
  </Grid>
 
  <Grid item>
    <TextField
  
  required
  label=" الاسم الاجنبي"
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,firstName:e.target.value})}}
  value={Pelgrim.firstName}
  error={Pelgrim.firstName.length<1?e=>checkdata():false}
  />
  </Grid>
  <Grid item>
    <TextField
  
  required
  label=" اسم الاب الاجنبي     "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,fatherName:e.target.value})}}
  value={Pelgrim.fatherName}
  />
   </Grid>
  <Grid item>
    <TextField
  
  required
  label="اسم الجد الاجنبي"
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,grandFatherName:e.target.value})}}
  value={Pelgrim.grandFatherName}
  />
   </Grid>
    <Grid item>
    <TextField
  
  required
  label="اللقب الاجنبي "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,lastName:e.target.value})}}
  value={Pelgrim.lastName}
  error={Pelgrim.lastName.length<1?e=>checkdata():false}
  />
   </Grid>
   
 
  </Grid>
 
 
  
 
  </div>
    </CardContent>
    </Card>
    <Accordion>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography> معلومات الجواز</Typography>
        </AccordionSummary>
        <AccordionDetails >
 <TextField
  
 required
 label=" رقم الجواز"
 id="filled-basic"
 variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,passportNumber:e.target.value})}}
 value={Pelgrim.passportNumber}
 error={Pelgrim.passportNumber<4?e=>checkdata():false}
 />


 
<LocalizationProvider dateAdapter={AdapterDateFns}>
  <DatePicker
   label=" تاريخ اصدار الجواز  "
    value={Pelgrim.passportDelieveryDate}
    onChange={(e)=>{setPelgrim({...Pelgrim,passportDelieveryDate:e})}}
    renderInput={(params) => <TextField {...params} />}
  />
  </LocalizationProvider>
   


 <LocalizationProvider dateAdapter={AdapterDateFns}>
  <DatePicker
   label="تاريخ انتهاء الجواز"
    value={Pelgrim.passportExpiryDate}
    onChange={(e)=>{setPelgrim({...Pelgrim,passportExpiryDate:e})}}
    renderInput={(params) => <TextField {...params} />}
  />
  </LocalizationProvider>
  
  <TextField
  
  required
  label="  مدينة الاصدار"
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,passportDelieveryCity:e.target.value})}}
  value={Pelgrim.passportDelieveryCity}
  error={Pelgrim.passportDelieveryCity<4?e=>checkdata():false}
  
                 
  />



<FormControl sx={{ m: 1, width: 200  }} style={{marginTop:"-1px"}} >
        <InputLabel id="demo-simple-select-autowidth-label">دولة الاصدار</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={Pelgrim.passportDeliveryCountry}
          onChange={e=>{setPelgrim({...Pelgrim,passportDeliveryCountry:e.target.value})}}
   
          label="دولة الاصدار"
          error={Pelgrim.passportDeliveryCountry<4?e=>checkdata():false}
        >
      
          {
            countrylist.countries.country.map(x=>{
              return (<MenuItem value={x.name_country_ar}>{x.name_country_ar}</MenuItem>)
            })
 
          }
        
     
        </Select>
      </FormControl>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>  معلومات الميلاد</Typography>
        </AccordionSummary>
        <AccordionDetails >

        <FormControl sx={{ m: 1, width: 200 }} style={{marginTop:"-1px"}}>
        <InputLabel id="demo-simple-select-autowidth-label"> الدولة</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={Pelgrim.birthCountry}
          onChange={e=>{setPelgrim({...Pelgrim,birthCountry:e.target.value})}}
          error={Pelgrim.birthCountry<1?e=>checkdata():false}
          label="الدولة"
        >
      
          {
            countrylist.countries.country.map(x=>{
              return (<MenuItem value={x.name_country_ar}>{x.name_country_ar}</MenuItem>)
            })
 
          }
        
     
        </Select>
      </FormControl>
         
      <TextField
  
  required
  label=" المدينة "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,birthCity:e.target.value})}}
  value={Pelgrim.birthCity}
  error={Pelgrim.birthCity<1?e=>checkdata():false}
  />


<LocalizationProvider dateAdapter={AdapterDateFns}>
  <DatePicker
    label="  التاريخ"
    value={Pelgrim.birthDate}
 
    onChange={e=>{setPelgrim({...Pelgrim,birthDate:e,age:moment(e, "MM/DD/YYYY").fromNow().split(" ")[0]})}}
    renderInput={(params) => <TextField {...params} />}
    error={Pelgrim.birthDate==null?e=>checkdata():false}
  />
  </LocalizationProvider>
                <TextField
  
  required
  label=" العمر "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,age:e.target.value})}}
  value={Pelgrim.age}
  />   
         
      

        </AccordionDetails>
        </Accordion>

        <Accordion>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>     متفرقات</Typography>
        </AccordionSummary>
        <AccordionDetails >

        <FormControl sx={{ m: 1, width: 200 }} style={{marginTop:"-1px"}}>
        <InputLabel id="demo-simple-select-autowidth-label"> الحالة الإجتماعية   </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={Pelgrim.civilState}
          onChange={e=>{setPelgrim({...Pelgrim,civilState:e.target.value})}}
   
          label="الحالة الإجتماعية  "
          error={Pelgrim.civilState.length<1?e=>checkdata():false}
        >
      
          {
             status.satus.map(x=>{
              return (<MenuItem value={x.name_ar}>{x.name_ar}</MenuItem>)
            })
 
          }
        
     
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: 200 }} style={{marginTop:"-1px"}}>
        <InputLabel id="demo-simple-select-autowidth-label">    التعليم   </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={Pelgrim.educationLevel}
          onChange={e=>{setPelgrim({...Pelgrim,educationLevel:e.target.value})}}
          error={Pelgrim.educationLevel.length<1?e=>checkdata():false}
          label="   التعليم  "
        >
      
          {
             education.education.map(x=>{
              return (<MenuItem value={x.name_ar}>{x.name_ar}</MenuItem>)
            })
 
          }
        
     
        </Select>
      </FormControl>
    
      <TextField
  
  required
  label=" الوظيفه "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,job:e.target.value})}}
  value={Pelgrim.job}
  error={Pelgrim.job.length<1?e=>checkdata():false}
  />   
        <FormControl sx={{ m: 1, width: 200 }} style={{marginTop:"-1px"}}>
        <InputLabel id="demo-simple-select-autowidth-label"> الجنسية   </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={Pelgrim.nationalityCountry}
          onChange={e=>{setPelgrim({...Pelgrim,nationalityCountry:e.target.value})}}
          error={Pelgrim.nationalityCountry.length<1?e=>checkdata():false}
          label="الجنسية  "
        >
      
          {
            countrylist.countries.country.map(x=>{
              return (<MenuItem value={x.name_country_ar}>{x.name_country_ar}</MenuItem>)
            })
 
          }
        
     
        </Select>
      </FormControl> 
      <FormControl sx={{ m: 1, width: 200 }} style={{marginTop:"-1px"}}>
        <InputLabel id="demo-simple-select-autowidth-label">        الحالة الصحية   </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={Pelgrim.healthStatus}
          onChange={e=>{setPelgrim({...Pelgrim,healthStatus:e.target.value})}}
          error={Pelgrim.healthStatus.length<1?e=>checkdata():false}
          label=" الحالة الصحية "
        >
      
          {
             health.healthstatus.map(x=>{
              return (<MenuItem value={x.name_ar}>{x.name_ar}</MenuItem>)
            })
 
          }
        
     
        </Select>
      </FormControl> 
      <FormControl sx={{ m: 1, width: 200 }} style={{marginTop:"-1px"}}>
        <InputLabel id="demo-simple-select-autowidth-label">   الجنس  </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={Pelgrim.gender}
          onChange={e=>{setPelgrim({...Pelgrim,gender:e.target.value})}}
          error={Pelgrim.gender.length<1?e=>checkdata():false}
          label="الجنس"
        >
      
      <MenuItem value="ذكر">ذكر</MenuItem>
      <MenuItem value="أنثى">أنثى</MenuItem>
        
     
        </Select>
 </FormControl>
      <FormControl sx={{ m: 1, width: 200 }} style={{marginTop:"-1px"}}>
        <InputLabel id="demo-simple-select-autowidth-label">نوع الغرفة  </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={Pelgrim.room}
          onChange={e=>{setPelgrim({...Pelgrim,room:e.target.value})}}
   
          label=" نوع الغرفة "
        >
      
          {
           taskin.taskin.map(x=>{
              return (<MenuItem value={x.name_ar}>{x.name_ar}</MenuItem>)
            })
 
          }
        
     
        </Select>
 </FormControl>
        
        

        </AccordionDetails>
        </Accordion>

        {/* <Accordion>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>  المحرم</Typography>
        </AccordionSummary>
        <AccordionDetails >
        صلة القرابة 
 
إسم المحرم 
        </AccordionDetails>
        </Accordion> */}
<Accordion>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>  تفاصيل بطاقة الإقامة</Typography>
        </AccordionSummary>
        <AccordionDetails >
    
  <Button color="secondary"   component="label"   >
    <img style={{float:"left",height:"250px",marginLeft:"100px"}}src={Pelgrim.respicturepath==null?"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR-VqdQ6_tW0Uw7J43-9bv8wa3zmdtxbWMMcA&usqp=CAU":Pelgrim.respicturepath} />
        <input hidden accept="image/*" multiple type="file" onChange={e=>{  
          getresBase64(e.target.files[0])
           
          setresfile(e.target.files[0]); 
     }}/>
      </Button>
      <hr></hr>
        <TextField
  
   
  label=" رقم بطاقة الإقامة"
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,resID:e.target.value})}}
  value={Pelgrim.resID}
  /> 
        <LocalizationProvider dateAdapter={AdapterDateFns}>
  <DatePicker
   label="تاريخ إصدار بطاقة الإقامة "
    value={Pelgrim.resdateissue}
    onChange={(e)=>{setPelgrim({...Pelgrim,resdateissue:e})}}
    renderInput={(params) => <TextField {...params} />}
  />
  </LocalizationProvider>
  <LocalizationProvider dateAdapter={AdapterDateFns}>
  <DatePicker
   label=" تاريخ انتهاء بطاقة الإقامة "
    value={Pelgrim.resdateexpyer}
    onChange={(e)=>{setPelgrim({...Pelgrim,resdateexpyer:e})}}
    renderInput={(params) => <TextField {...params} />}
  />
  </LocalizationProvider>

  
 

        </AccordionDetails>
        </Accordion>




        <Accordion> 
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>  معلومات الرحلة</Typography>
        </AccordionSummary>
        <AccordionDetails >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
  <DatePicker
   label="تاريخ الوصول"
    value={Pelgrim.dateArrival}
    onChange={(e)=>{setPelgrim({...Pelgrim,dateArrival:e})}}
    renderInput={(params) => <TextField {...params} />}
  />
  </LocalizationProvider>
  <TextField
  
   
  label=" مدة إقامتك المتوقعة بالأيام"
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,stayPeriod:e.target.value})}}
  value={Pelgrim.stayPeriod}
  /> 
        


<FormControl sx={{ m: 1, width: 200 }} style={{marginTop:"-1px"}}>
        <InputLabel id="demo-simple-select-autowidth-label">منفذ الوصول</InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={Pelgrim.wayPort}
          onChange={e=>{setPelgrim({...Pelgrim,wayPort:e.target.value})}}
   
          label="منفذ الوصول  "
        >
      
          {
           portofentry.portOfEntryMap.map(x=>{
              return (<MenuItem value={x.name_ar}>{x.name_ar}</MenuItem>)
            })
 
          }
        
     
        </Select>
 </FormControl>
 
<FormControl sx={{ m: 1, width: 200 }} style={{marginTop:"-1px"}}>
        <InputLabel id="demo-simple-select-autowidth-label">   طريقة الوصول  </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={Pelgrim.transport}
          onChange={e=>{setPelgrim({...Pelgrim,transport:e.target.value})}}
   
          label=" طريقة الوصول  "
        >
      
          {
           transport.transport.map(x=>{
              return (<MenuItem value={x.name_ar}>{x.name_ar}</MenuItem>)
            })
 
          }
        
     
        </Select>
 </FormControl>
 <TextField
  
   
  label="رقم الرحلة "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,flightNumber:e.target.value})}}
  value={Pelgrim.flightNumber}
  /> 
        </AccordionDetails>
        </Accordion>
        <Accordion>
        <AccordionSummary
          expandIcon={<MdExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>  معلومات التأشير</Typography>
        </AccordionSummary>
        <AccordionDetails >

        <TextField
  
   
  label="رقم طلب"
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,wakilNumber:e.target.value})}}
  value={Pelgrim.wakilNumber}
  />   
          <TextField
  
   
  label="رقم التأشيرة "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,visaNumber:e.target.value})}}
  value={Pelgrim.visaNumber}
  /> 
            {/* <TextField
  
   
  label="رقم مقيم "
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setPelgrim({...Pelgrim,visaNumber:e.target.value})}}
  value={Pelgrim.moqumNumber}
  />  */}
        </AccordionDetails>
        </Accordion>
        <div style={{height:"100px"}}></div>
        <Card variant="outlined"  >
   <CardContent> 
   <Grid container spacing={2} style={{position:"fixed" ,bottom:"5px",width:"100%" ,background:"white"}}>  
 
   <Grid item  >
  
   <Button style={{width:"200px" ,height:"50px",marginTop:"10px"}}  size="large" variant="contained"   onClick={e=>{addMuatamr();}}>  {typeof Pelgrim.id=="undefined"?" حفظ واضافة جواز جديد":"حفظ"}         </Button>
   
   </Grid >  
   <Grid item  > 
   <Button style={{ height:"50px",marginTop:"10px"}} size="large" variant="contained"   onClick={e=>history.push("/Pelgrims/")}> العودة    </Button>
     </Grid >  
   </Grid >  
    </CardContent>
    </Card>


       
    
    </div>
  
)
}
 