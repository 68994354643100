import { FormControl, InputLabel, Select,MenuItem, IconButton, Button, Grid, Modal, Card, CardContent, Typography, TextField, CardActionArea, Avatar } from "@mui/material";
import { Container } from "@mui/system";
import { arSD, DataGrid } from "@mui/x-data-grid";
import React, { Component, useEffect, useState } from "react";
import { CgArrowsExchangeAlt } from "react-icons/cg";
 

import grouppingService from "../services/groupping.services";
import PelgrimsService from "../services/Pelgrims.services";
import { useHistory } from "react-router-dom";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { MdAddCircle, MdAddCircleOutline, MdDelete     } from "react-icons/md";
import SeleniumServices from "../services/seleniumServices";
import { scryRenderedComponentsWithType } from "react-dom/test-utils";
import loadingicon from "./loading.gif"
import AgencyService from "../services/Agency.services";
import moment from "moment/moment";
import ReportingServices from "../services/reporting.services";
import { Print } from "./Print";
import { AiFillPrinter } from "react-icons/ai";
import { Status } from "./status";
import DropdownButton from "./Fileselector";
  
  export const   ReportsSelector =({ ...props }) => {
    const [filter, setfilter] = useState (1); 
    const [reporttype, setreporttype] = useState ("PDF");
    const [groupidList1, setgroupidList1] = useState ();
    const [groupidList2, setgroupidList2] = useState ();
    const [groupsList, setgroupsList] = useState ([]);
    const [loading, setloading] = useState (false);
    const [loadingdata, setloadingdata] = useState (false);
    const [filterrapport, setfilterrapport] = useState (1);
    const [mutamarsList1, setmutamarsList1] = useState ([]);
    const [mutamarsList2, setmutamarsList2] = useState ([]);
    const [passportNumber, setpassportNumber] = useState ("");
    const [printview,setprintview ]=useState (false);
    const[Agency,setAgency]=useState([]);
    const[selectedagency,setselectedagency]=useState(JSON.parse(localStorage.getItem("Agency")).id);
    const [selected, setselected] = useState ([]);
    const [textofind, settextofind] = useState ();
    const [badgemodelid, setbadgemodelid] = useState ("0");
    
    const _grouppingService = new grouppingService();
    const _pelgrimsService = new PelgrimsService();
    const _reportingServices = new ReportingServices();
    const _agencyService = new AgencyService();
    const[badgeparm,setbadgeparm]=useState({
      MakkaHotel:"",
    MedinaHotel:"",
    ChefGroupe:"",
    Datego:""} );
    function printrapport(){
       
     
      let body={

    rapporttype: filterrapport+"" ,
     
      parms:
      {
        "MakkaHotel":badgeparm.MakkaHotel,
      "MedinaHotel":badgeparm.MedinaHotel,
      "ChefGroupe":badgeparm.ChefGroupe,
      "Datego":badgeparm.Datego,
      "raporttype":reporttype,
    "raportname":badgemodelid,
    "agencyid":''+JSON.parse(localStorage.getItem("user")).DATBASENAME} 
    ,
    mutamarsList:mutamarsList2 
    }
    setloading(true);
    setprintview(false);
      _reportingServices.getrapport(body).then(res=>{
        setloading(false);
        
        if(reporttype=="PDF"){ 
    
        var b = new Blob([res.data],{encoding:"UTF-8",type:"application/pdf;charset=UTF-8"});

        var url = URL.createObjectURL(b);
        window.open(url,"_blank","");
        }
     if(reporttype!="PDF"){
      b = new Blob([res.data],{encoding:"UTF-8",type:"application/doc;charset=UTF-8"});

          
    if(reporttype=="WORDOPENXML"){
      var filename = "file.doc"; 
    }
    if(reporttype=="EXCELOPENXML"){
      var filename = "file.xls"; 
    }
     var url = URL.createObjectURL(b);
     // Replace "extension" with the desired file extension
     var link = document.createElement('a');
     link.href = url;
     link.download = filename;
     link.click();
     }
     
    
       
    
     
      }).catch(ee=>{
        setloading(false);
      }
      
     )

     
    }

    function startprint(){
      if(filterrapport==1){
        setprintview(true);
       
      }else{
        printrapport();
      }
    

    }
    function getgroups(x){
      _grouppingService.getgroups(x).then(res=>{
      
        setgroupsList(res.data.records);
    
      }).catch(res=>{
        NotificationManager.error('يرجى التحقق من البينات', '');
      }

      )
  }
   
     
    function getpelgrimsList1(x){
      setmutamarsList1([]);
 
         
      _pelgrimsService.getmutbygroup(groupsList[x].fields.ID).then(res=>{
   

   res.data.records.map(x2=>{
     setmutamarsList1((prevArray) => [...prevArray,  x2]);
   
     setloadingdata(false);

   })
 
      }
  
      )

     
      
       
    }

    function exchangeall(x){
      mutamarsList1.map(x=>{
        setmutamarsList2(mutamarsList2=>[...mutamarsList2,x]);
      })
      setmutamarsList1([]);
      }
      function exchange(x){
     
        setmutamarsList2(mutamarsList2=>[...mutamarsList2,x]);
        
        
        
        setmutamarsList1(mutamarsList1.filter(el=>el!=x));
      
          
      }
      function Delete(x){
         
        if(groupidList1==x.groupingId){
          setmutamarsList1(mutamarsList1=>[...mutamarsList1,x]);
        }
  
        setmutamarsList2(mutamarsList2.filter(el=>el!=x));
    
      }
  
    const handleChange3 = (event) => {
        
      setselectedagency(event.target.value);
      getgroups(event.target.value);
      

      
  };
  const handleChange2 = (event) => {
        
    setgroupidList1(event.target.value);
    setmutamarsList1([]);
    if(groupsList[event.target.value].fields.Mutamrs!== undefined){
    getpelgrimsList1(event.target.value)
setloadingdata(true);}
    
};
    function getagency(x){
      _agencyService.getagency(x).then(res=>{
        res.data.map( x=>{
          x.createdDate=moment(x.createdDate).format('MM/DD/YYYY');
         
        }
        )
        setAgency(res.data);
      })
     
     
    }
    const handleOptionSelect = (option) => {
 
      setreporttype(option);
    };
 
    const columns = [
     
      { field: 'arabicName ', headerName: 'الإسم الكامل ', width: 250, renderCell: (parm) => {
        return (
          <div style={{display:"flex"}}>
          
          <Avatar sx={{ width: 50, height: 50 }}  src={parm.row.fields.pilgrimPicturePath!=null?parm.row.fields.pilgrimPicturePath[0].thumbnails.small.url:""}  ></Avatar>
      
       
          <div style={{display:"flex",flexDirection:"column" ,marginTop:"5px"}}>
            <span className="namestyle">
              {parm.row.fields.arabicName+" "+ parm.row.fields.arabicLastName}
              </span>
<span className="namestyle">
{parm.row.fields.firstName+" "+ parm.row.fields.lastName}
</span>
          </div>
          </div>
        )} },
      
 
      
      { field: 'passportNumber', headerName: 'رقم الجواز', width: 120  ,valueGetter: params => params.row.fields.passportNumber },
  
        { field: 'pilgrimPicturePath', headerName: 'رقم (التأشيرة,الموافقة) ', width: 140 ,
        renderCell: (parm) => {
          return (
            <div style={{display:"flex" ,flexDirection:"column"}} >
             <span>{parm.row.fields.wakilNumber}</span> 
             <span>{parm.row.fields.visaNumber}</span> 
   
            </div>
          );
        }
      },
        {
          field: "actions",
          headerName: "العمليات",
          sortable: false,
          width: 80,
          disableClickEventBubbling: true,
          renderCell: (parm) => {
            return (
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ cursor: "pointer" }}
              >
                <IconButton className="addicon" aria-label="addtolist" size="large" onClick={e=>exchange(parm.row)}>
        <MdAddCircleOutline   />
      </IconButton>
      
  
              </div>
            );
          }
        }
      ];
      const columns2  = [
     
        { field: 'arabicName ', headerName: 'الإسم الكامل ', width: 250, renderCell: (parm) => {
          return (
            <div style={{display:"flex"}}>
            
            <Avatar sx={{ width: 50, height: 50 }}  src={parm.row.fields.pilgrimPicturePath!=null?parm.row.fields.pilgrimPicturePath[0].thumbnails.small.url:""}  ></Avatar>
        
         
            <div style={{display:"flex",flexDirection:"column" ,marginTop:"5px"}}>
              <span className="namestyle">
                {parm.row.fields.arabicName+" "+ parm.row.fields.arabicLastName}
                </span>
  <span className="namestyle">
  {parm.row.fields.firstName+" "+ parm.row.fields.lastName}
  </span>
            </div>
            </div>
          )} },
        
   
        
        { field: 'passportNumber', headerName: 'رقم الجواز', width: 120  ,valueGetter: params => params.row.fields.passportNumber },
    
  
      { field: 'pilgrimPicturePath', headerName: 'رقم (التأشيرة,الموافقة) ', width: 140 ,
      renderCell: (parm) => {
        return (
          <div style={{display:"flex" ,flexDirection:"column"}} >
           <span>{parm.row.fields.wakilNumber}</span> 
           <span>{parm.row.fields.visaNumber}</span> 
 
          </div>
        );
      }
    },
        {
          field: "actions",
          headerName: "العمليات",
          sortable: false,
          width: 100,
          disableClickEventBubbling: true,
          renderCell: (parm) => {
            return (
              !loading?
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ cursor: "pointer" }}
              >
                <IconButton  className="deleteicon"  aria-label="addtolist" size="large" onClick={e=>Delete(parm.row)}>
        <MdDelete       />
      </IconButton>
      
  
              </div>:(passportNumber==parm.row.passportNumber?<img src={loadingicon} style={{height:"35px"}}/>:"")
            );
          }
        }
      ];
  useEffect(() => {
    getgroups();
    
  },[])
  return(

    
    <div>

<Modal
    
  open={printview}
  onClose={e=>setprintview(false)}
  aria-labelledby="parent-modal-title"
  aria-describedby="parent-modal-description"
 >
 <Card style={{position:"absolute",left:"40%",top:"10%",width:"400px" }}>
  <CardContent dir="rtl">
  
  <div className="tpg" style={{margin:"20px"}} >
    معلومات التقرير
          </div>
          <div style={{padding:"15px"}}>
          <FormControl    style={{width:"100%" ,marginTop:"10px"}}>
        <InputLabel id="demo-simple-select-autowidth-label">نموذج البطاقة </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={badgemodelid}
     
        onChange={e=>setbadgemodelid(e.target.value)}
          
          label=" اسم المجموعة"
        >
          <MenuItem value="0">
          نموذج 1
          </MenuItem>
          <MenuItem value="1">
          2 نموذج
          </MenuItem>
           


      
 
        </Select>
      
      </FormControl>
  <TextField
  style={{width:"100%" ,marginTop:"10px"}}
  required
  label="النزل بمكة"
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setbadgeparm({...badgeparm,MakkaHotel:e.target.value});}}
  value={badgeparm.MakkaHotel}
  dir="rtl"
  />
    <TextField
  style={{width:"100%" ,marginTop:"10px"}}
  required
  label="النزل بالمدينة"
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setbadgeparm({...badgeparm,MedinaHotel:e.target.value});}}
  value={badgeparm.MedinaHotel}
  dir="rtl"
  />
      <TextField
  style={{width:"100%" ,marginTop:"10px"}}
  required
  label=" رئيس المجموعة"
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setbadgeparm({...badgeparm,ChefGroupe:e.target.value});}}
  value={badgeparm.ChefGroupe}
  dir="rtl"
  />
       <TextField
       dir="rtl"
  style={{width:"100%" ,marginTop:"10px"}}
  required
  label=" تاريخ الذهاب و الاياب"
  id="filled-basic"
  variant="outlined"
  onChange={e=>{setbadgeparm({...badgeparm,Datego:e.target.value});}}
  value={badgeparm.Datego}
 
  />
  </div>
  <Button style={{ height:"50px" ,margin:"10px" }} size="large" variant="contained" onClick={e=>{printrapport(); }} >طباعة </Button>
 
  </CardContent>
 
 
  
 </Card>
</Modal>
 

    <Grid   container spacing="10" >
    <Grid item xs={5.5} sm={5.5} md={5.5} >
    <div>
     
 {/* <FormControl sx={{ m: 1, width: 200 }}>
        <InputLabel id="demo-simple-select-autowidth-label">بحث حسب  </InputLabel>
        <Select
        disabled={loading}
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={filter}
      
          onChange={handleChange}
          
          label="بحث حسب"
        >
          <MenuItem value="">
            <em>  </em>
          </MenuItem>
          <MenuItem value={1}>بحث حسب المجموعة</MenuItem>
          <MenuItem value={2}>بحث حسب رقم الجواز</MenuItem>
          <MenuItem value={3}>بحث حسب اسم المعتمر</MenuItem>
        </Select>
      </FormControl> */}
   
        <div style={{display:"flex"}}>
        
        <FormControl sx={{ m: 1, width: 500 }}>
        <InputLabel id="demo-simple-select-autowidth-label"> المجموعة  </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={groupidList1}
          disabled={loadingdata}
          onChange={handleChange2}
          
          label=" اسم المجموعة"
        >
          <MenuItem value="">
            <em>  </em>
          </MenuItem>
          {
            groupsList.map( (x,index) =>{
              return <MenuItem value={index}>
                <div dir="RTL"> 
<table style={{fontSize:"14px"}}>
  <tr>
  <td style={{fontSize:"20px"}}>  {x.fields.name+" "}   </td>

  <td><Status  data={{"total":x.fields.totalmutamr,"visa":x.fields.totalvisa,"mouwaf9a":x.fields.totalmouwaf9a}}/> </td>
 
  
  </tr>
</table>


</div>
                
              
                
                </MenuItem>


          })
          }
 
        </Select>
      
      </FormControl>
      </div>
     
    </div>
     
    
                  <DataGrid 
 density={"comfortable"}
       dir="rtl"
      localeText={arSD.components.MuiDataGrid.defaultProps.localeText} 
style={{height:"65vh" }}
        rows={mutamarsList1}
        columns={columns}
        rowsPerPageOptions={[5,10,100,1000]}
        disabled={loading}
        loading={loadingdata}
      />
     
      
      </Grid>
      <Grid item>
      <div style={{height:"50vh"}}></div>
      <IconButton  className="exchangeall" disabled={loading} aria-label="addtolist" size="large" onClick={e=>exchangeall()}>
        <FaArrowAltCircleLeft   />
      </IconButton>
      
      </Grid>
      <Grid item xs={5.5} sm={5.5} md={5.5} >

     <Print setindex={e=>{setfilterrapport(e)}}></Print>
     { /*<FormControl sx={{ m: 1, width: 200 , }}>
        <InputLabel id="demo-simple-select-autowidth-label"> نوع التقرير </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={filterrapport}
      
          onChange={e=>setfilterrapport(e.target.value)  }
          
          label=" نوع التقرير   "
        >
          <MenuItem value="">
            <em>  </em>
          </MenuItem>
          <MenuItem value={1}> بطاقة المعتمر</MenuItem>
          <MenuItem value={2}> صور الجوازات </MenuItem>
          <MenuItem value={3}> قائمة المعتمرين بالاجنبي </MenuItem>
          <MenuItem value={4}> قائمة المعتمرين بالعربي </MenuItem>
          <MenuItem value={5}>  صور التأشيرات </MenuItem>

        </Select>
        </FormControl>*/}
         <div  ></div>
                  <DataGrid 
 density={"comfortable"}
       dir="rtl"
      localeText={arSD.components.MuiDataGrid.defaultProps.localeText} 
style={{height:"65vh" }}
            rows={mutamarsList2}
            columns={columns2}
            rowsPerPageOptions={[5,10,100,1000]}
          
            loading={loading}
          />
            <Button  className="printbutton" disabled={mutamarsList2.length==0||loading}    size="large" variant="contained"  onClick={e=>{startprint();}} 
         
         
         
         
         >
           <AiFillPrinter></AiFillPrinter>
           <span>طباعة</span>
           </Button>
           <DropdownButton onSelect={handleOptionSelect}></DropdownButton>
 
       </Grid>
      </Grid>
  

    </div>

     
    
  )
  };
