import { Button, Card, CardActions, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
 
 
import "react-toastify/dist/ReactToastify.css";
import {GroupMuatmasselector} from "../../components/GroupMuatmasselector";
 import PelgrimsService from "../../services/Pelgrims.services";

 
 
export default function Pelgrims({ ...props }) {
const[mutamars,setmutamars]=useState([]);


 
 


const _PelgrimsService = new PelgrimsService();

 
useEffect(()=>{
  
 

},[])
return(
  <div>
    <Card variant="outlined"  >
   <CardContent> 
    <GroupMuatmasselector mutamars={mutamars} setmutamars={setmutamars}></GroupMuatmasselector>

    </CardContent>
 
    </Card>
    
    </div>
  
)
}
 