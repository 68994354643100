import request from "../configs";

// import { authHeader } from "./auth-header";
export default class SeleniumServices {
 
    constructor() {
       
    
      this.request = request;
     
      };
   
   
      Startbrowser(body){
        let requestOptions =  {
         headers:{'Content-Type': 'application/json'}
        
        };
        
        
          return this.request.post("http://localhost:8733/Startbrowser",null,{
            headers: {
              "Content-Type": "application/json",
            },
            
          });
      }
      Sendtonosok(body,embasy,name){
        let requestOptions =  {
          
        };
        //PICRES=job
        //dateres=mohramPaassportNumber
        //datexpyer=flightNumber
        //reid=TitleId
         
       let bodytosend={
           embassy:"216",
            datearrival:body.dateArrival,
            groupename:name,
            browser:"",
            user:"",
            password:"",

            mutamerData:{
              passportimage:" ",
              pilgrimimage:body.pilgrimPicturePath[0].url,
                  PPMRZ  :body.mrz,
                  mohramName  :"",
                  PPIssDate  :body.passportDelieveryDate,
                  PPExpDate  :body.passportExpiryDate,
                  GenderId  :body.gender=="أنثى"?0:1,
                  PPIssueCountry  :body.birthCountry,
                  BirthDate  :body.birthDate,
                  BirthCountry  :body.birthCountry,
                  AddressCountry  :body.passportDeliveryCountry,
                  PPIssueCity  :body.passportDelieveryCity,
                  Job  :body.job,
                  PPTypeCode  :"",
                  EducationCode  :body.educationLevel,
                  PilgrimId  :"",
                  BirthCity  :body.birthCity,
                  RelationWithMohram  :"",
                  TitleId  :"",
                  mohramPaassportNumber  :"",
                  EFirstName  :body.firstName,
                  EFatherName  :"",
                  EGFatherName  :"",
                  EFamilyName  :body.lastName,
                  MaritalStatusCode  :body.civilState,
                  AFirstName  :body.arabicName,
                  passportNumber  :body.passportNumber,
                  AFatherName  :"",
                  AGFatherName  :"",
                  AFamilyName  :body.arabicLastName,
                  Nationality  :body.nationalityCountry,
                  flightNumber  :body.flightNumber,
            }



        }
      
         
        const url=()=>{switch(JSON.parse(localStorage.getItem("Agency")).wakil){
          case 1:return "SetCustomerTAWAF";
           
          case 2:return "SetCustomerGAMA";
          
          case 3:return "SetCustomerW2U";
         
          case 4:return "SetCustomerbebumrah";
           
        }}
        
          return this.request.post("http://localhost:8733/SetCustomerNOSOK",bodytosend,{
            headers: {
              "Content-Type": "application/json",
            },
            
          });
        
      }
      
      Sendtowakil(body,embasy,name){
        let requestOptions =  {
          
        };
        
       let bodytosend={
            embassy:JSON.parse(localStorage.getItem("Agency")).city,
            datearrival:JSON.parse(localStorage.getItem("Agency")).googleAuthenticator?JSON.parse(localStorage.getItem("Agency")).googleAuthenticator:"",
            groupename:name,
            browser:"",
            user: JSON.parse(localStorage.getItem("Agency")).wakilUser,
            password:JSON.parse(localStorage.getItem("Agency")).wakilpassword,

            mutamerData:{
              passportimage:body.passportPicturePath[0].url,
              pilgrimimage:body.pilgrimPicturePath[0].url,
                  PPMRZ  :body.mrz,
                  mohramName  :JSON.parse(localStorage.getItem("Agency")).mail,
                  PPIssDate  :body.passportDelieveryDate,
                  PPExpDate  :body.passportExpiryDate,
                  GenderId  :body.gender=="أنثى"?0:1,
                  PPIssueCountry  :body.birthCountry,
                  BirthDate  :body.birthDate,
                  BirthCountry  :body.birthCountry,
                  AddressCountry  :body.passportDeliveryCountry,
                  PPIssueCity  :body.passportDelieveryCity,
                  Job  :body.job,
                  PPTypeCode  :"",
                  EducationCode  :body.educationLevel,
                  PilgrimId  :"",
                  BirthCity  :body.birthCity,
                  RelationWithMohram  :body.gender=="أنثى"?"رفقة آمنة":"",
                  TitleId  :body.gender=="أنثى"?"السش":1,
                  mohramPaassportNumber  :"",
                  EFirstName  :body.firstName,
                  EFatherName  :body.fatherName,
                  EGFatherName  :body.grandFatherName,
                  EFamilyName  :body.lastName,
                  MaritalStatusCode  :body.civilState,
                  AFirstName  :body.arabicName,
                  passportNumber  :body.passportNumber,
                  AFatherName  :body.arabicFatherName,
                  AGFatherName  :body.arabicGrandfatherName,
                  AFamilyName  :body.arabicLastName,
                  Nationality  :body.nationalityCountry,
                  flightNumber  :body.flightNumber,
            }



        }
      
         
        const url=()=>{switch(JSON.parse(localStorage.getItem("Agency")).wakil){
          case 1:return "SetCustomerTAWAF";
           
          case 2:return "SetCustomerGAMA";
          
          case 3:return "SetCustomerW2U";
         
          case 4:return "SetCustomerbebumrah";
          case 5:return "SetCustomerNOSOKb2b";
        }}
        
          return this.request.post("http://localhost:8733/"+url(),bodytosend,{
            headers: {
              "Content-Type": "application/json",
            },
            
          });
        
      }
      
      Sendtovisa(body){
        let requestOptions =  {
          headers: {
            Authorization:
              "bearer " +
              (localStorage.getItem("user") &&
                typeof localStorage.getItem("user") != "undefined" &&
                JSON.parse(localStorage.getItem("user")).token),
          }
        };

        var bodytosend={
          id  :body.wakilNumber,
      passport  :body.passportNumber,
      passportDeliveryCity  :"",
      passportExpiryDate  :"",
      sex  :"",
      birthDate  :"",
      nationality  :body.nationalityCountry,
      birthCity  :body.birthCity,
      job  : body.job,
      visaTransportWay  :body.transport,
      placeOfIssue  :"",
      DurationOfStay  :"",
      tel  :"",
      addressZipCode  :"",
      addressCity  :"",
      email  :"COMMERCIAL@awslabs.tn",
      portOfEntry  :"",
      travalDate  :"",
      expectedDurationOfYourStay  :body.stayPeriod,
      flightNumber  :body.flightNumber,
      civilState  :body.civilState,
      birthCountry  :"",
      wayport  :body.wayPort,
      addressCountry  :"",
      MRZ:body.mrz
        }
        
        
          return this.request.post("http://localhost:8733/SetCustomer",bodytosend,{
            headers: {
              "Content-Type": "application/json",
            },
            
          });
        
      }


       
  }