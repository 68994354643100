import axios from "axios";

export default axios.create({
 
  // baseURL: `https://localhost:5001/`, 
 baseURL: "https://api.airtable.com/v0/",
 //baseURL: `http://api.walidvoyages.com/`,
 //baseURL:`http://hajirapi.awslabs.tn/`  ,
});

 
 
