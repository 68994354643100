import React from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
 
import Header from "./header";

import { routes } from "./routing/routes";
import { createBrowserHistory } from "history";
import Aside from "./aside";
import Login from "../screens/Auth/Login"
import NotificationContainer from "react-notifications/lib/NotificationContainer";
 

const Main = ({
  collapsed,
  rtl,
  image,
  handleToggleSidebar,
  handleCollapsedChange,
  handleRtlChange,
  handleImageChange,
  component,
  toggled,
}) => {
  return (
   
    <Router history={createBrowserHistory()}>
          <NotificationContainer />
      <Switch>
        {routes.map(({ component: Component, ...rest }) => {
          return (
            <Route
              render={(matchProps) =>
                
             ( rest.isPublic && !localStorage.getItem("user") ) ?(
                  <div className="auth">
                    <Component {...matchProps}></Component>
                  </div>
                ) :
                
                ( !localStorage.getItem("user")?
                <div className="auth">
                <Login {...matchProps}></Login>
              </div>
              :  rest.isPublic ? <Redirect to='/' />:
                  <>
                    <div
                      className={`app ${rtl ? "rtl" : ""} ${
                        toggled ? "toggled" : ""
                      }`}
                    >
                      <Aside
                        collapsed={collapsed}
                        rtl={rtl}
                        toggled={toggled}
                        handleCollapsedChange={handleCollapsedChange}
                        handleToggleSidebar={handleToggleSidebar}
                      />
                      <main>
                        {" "}
                        <Header
                          title={rest.title}
                          handleToggleSidebar={handleToggleSidebar}
                        />
                        <div className="outlet">
                          <Component {...matchProps}></Component>
                        
                        </div>
                        
                      </main>
                    </div>
                  </>
                )
              }
              {...rest}
            />
          );
        })}
      </Switch>
    </Router>
  );
};
export default Main;
