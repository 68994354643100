 
import React, { Component, useEffect, useState } from "react";
 
 import{
  IoIosPeople
}from "react-icons/io";
import{
  FaCcVisa
}from "react-icons/fa";
import{
  BsFillPersonCheckFill
}from "react-icons/bs";
 
  
  export const   Status =({data} ) => {
  
  
  return(
    <div style={{display:"flex"}}>
  
  <div className="v2"></div>
      <div   style={{fontWeight:"bold"}}>  <IoIosPeople style={{fontSize:"25px",color:"#41c0e9"}}/> {data.total}   </div>
      <div className="v2"></div>
      <div   style={{fontWeight:"bold"}}><FaCcVisa style={{fontSize:"25px",color:"#1cc3af"}}/>  {data.visa}  </div>
      <div className="v2"></div>
      <div style={{fontWeight:"bold"}} ><BsFillPersonCheckFill style={{fontSize:"23px",color:"#0065af"}}/> {data.mouwaf9a} </div>
   
 
     </div>  
    
  )
  };
