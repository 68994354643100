import { Card, CardContent } from "@mui/material";
import React, { useEffect, useState } from "react";
 
 
import "react-toastify/dist/ReactToastify.css";
import { Exchangeselector } from "../../components/Exchangeselector";
import {GroupMuatmasselector} from "../../components/GroupMuatmasselector";
 import PelgrimsService from "../../services/Pelgrims.services";

 
 
export default function ExchangePelgrim({ ...props }) {
const[mutamars,setmutamars]=useState([]);


 
 


const _PelgrimsService = new PelgrimsService();

 
useEffect(()=>{
  
 

},[])
return(
  <div>
    <Card variant="outlined"  >
   <CardContent> 
    <Exchangeselector mutamars={mutamars} setmutamars={setmutamars}/>

    </CardContent>
    </Card>
    
    </div>
  
)
}
 