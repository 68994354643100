import React, { useState } from "react";
import Aside from "./aside";
import Main from "./main";
import "./styles.scss";
import tawk from "../tawk"

function Layout({ setLocale }) {
  const [rtl, setRtl] = useState(true);
  const [collapsed, setCollapsed] = useState(false);
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);
    
  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleRtlChange = (checked) => {
    setRtl(checked);
    // setLocale(checked ? "ar" : "en");
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };
 
 
 


  return (

    <Main
      toggled={toggled}
      collapsed={collapsed}
      rtl={rtl}
      handleToggleSidebar={handleToggleSidebar}
      handleCollapsedChange={handleCollapsedChange}
      handleRtlChange={handleRtlChange}
    />
  
  );
 

}

export default Layout;
