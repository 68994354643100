import React, { useEffect, useState } from "react";
import go from "../../selenium/s";
 import grouppingService from "../../services/groupping.services";
 import { arSD, DataGrid } from '@mui/x-data-grid';
import { Box, Button, Card, CardActions, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputBase, Paper, TextField, Typography } from "@mui/material";
import { FormControl } from "react-bootstrap";
import {
  MdDelete  ,  Forever,
  MdEdit
  
} from "react-icons/md";
import {
  AiFillEye,
  AiFillEyeInvisible,
 
  
} from "react-icons/ai";

import{
  IoIosPeople
}from "react-icons/io";
import{
  FaCcVisa
}from "react-icons/fa";
import{
  BsFillPersonCheckFill
}from "react-icons/bs";
import moment from "moment";
import { BsSearch } from "react-icons/bs";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { margin } from "@mui/system";
import { Status } from "../../components/status";
 
 
 
export default function Groups({ ...props }) {
const[groups,setgroups]=useState([]);
const[group,setgroup]=useState({name:""});
const[ idtoupdate,setidtoupdate]=useState("");
const[groupenamefilter,setgroupenamefilter]=useState("");
const [filterModel, setFilterModel] = useState( );
const [open, setOpen] =useState(false);
const [idtoremove, setidtoremove] =useState(null);
const [loadingdata, setloadingdata] = useState (false);
const [statistic, setstatistic] = useState ({"totoalmutamr":0,"totoalvisa":0,"totalmouf9a":0});
const [visible, setvisible]=useState("TRUE");
const [groupeedit, setgroupeedit]=useState([]);


function handleClickOpen (x)  {
  setidtoremove(x);
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};
 
const _grouppingService = new grouppingService();
function cancel(){
  setgroup({name:""});
  setidtoupdate("");
  
}
function addgroupe(){
  if(group.name.replace(/\s/g,"") == ""){

    NotificationManager.error(' اسم المجموعة لايمكن ان يكون فارغ', '');

  }else{

    group.id=idtoupdate;
    group.Mutamrs=groupeedit.Mutamrs;
  _grouppingService.addgroup(group).then(res=>{
    cancel();
    getgroups();
    if(typeof group.id!="undefined"){
      NotificationManager.success('تم اضافة بنجاح', '');
    }else{
      NotificationManager.success('تم التعديل بنجاح', '');
    }

   console.log(res.data);
  }).catch(res=>{
    NotificationManager.error('يرجى التحقق من البينات', '');
  }

  )
}
}
function Hidegroupe(id){
   
  _grouppingService.Hide(id).then(res=>{
   
    getgroups();
    NotificationManager.success('تم الحذف بنجاح', '');
    setOpen(false);
  }).catch(res=>{
    NotificationManager.error('يرجى التحقق من البينات', '');
    setOpen(false);
  }

  )
  
}
function Editgroupe(data,id){
   
  setgroup({name:data});
 setidtoupdate(id)
}
function deletegroupe(){
  _grouppingService.deletegroup(idtoremove).then(res=>{
   
    getgroups();
    NotificationManager.success('تم الحذف بنجاح', '');
    setOpen(false);
  }).catch(res=>{
    NotificationManager.error('يرجى التحقق من البينات', '');
    setOpen(false);
  }

  )
}

function getgroups(){
  setloadingdata(true);
  let totoalmutamr=0;
  let totoalvisa=0;
  let totalmouf9a=0;
  let totalgroupe=0;
  _grouppingService.getgroups(visible).then(res=>{
  
  
 
    setgroups(res.data.records);
    res.data.records.forEach(element => {
      totoalmutamr=totoalmutamr+element.fields.totalmutamr;
      totoalvisa=totoalvisa+element.fields.totalvisa;
      totalmouf9a=totalmouf9a+element.fields.totalmouwaf9a;
    });
    setstatistic({"totoalmutamr":totoalmutamr,"totoalvisa":totoalvisa,"totalmouf9a":totalmouf9a});
    setloadingdata(false);
  })
 
 
}




const columns = [
  { field: 'name', headerName: 'اسم المجموعة', width: 150,
  valueGetter: params => params.row.fields.name },
  { field: 'createdDate', headerName: 'تاريخ الانشاء', width: 150,
  valueGetter: params => moment(params.row.createdTime).format('MM/DD/YYYY')  },
  {
    field: "Statstique",
    headerName: "الاحصائيات",
    sortable: false,
    width: 250,
    disableClickEventBubbling: true,
    renderCell: (parm) => {
      return (
<div> 
<Status data={{"total":parm.row.fields.totalmutamr,"visa":parm.row.fields.totalvisa,"mouwaf9a":parm.row.fields.totalmouwaf9a}}/> 
 {console.log(parm.row.fields.totalmutamr)}
 
 </div>

      )
    }},
  {
    field: "actions",
    headerName: "العمليات",
    sortable: false,
    width: 160,
    disableClickEventBubbling: true,
    renderCell: (parm) => {
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          {  <IconButton aria-label="delete" size="large" onClick={e=>handleClickOpen(parm.id)}>
  <MdDelete    className="deleteicon"/>
</IconButton>  }
<IconButton aria-label="delete" size="large" onClick={e=>{Editgroupe(parm.row.fields.name,parm.id);setgroupeedit(parm.row.fields)}}>
  <MdEdit   />
</IconButton>
<IconButton aria-label="delete" size="large" onClick={e=>Hidegroupe(parm.row.id)}>
  {parm.row.fields.Visible=="true"?<AiFillEyeInvisible   />:<AiFillEye/>}
</IconButton>
        </div>
      );
    }
  }
];

 
 
 
useEffect(()=>{
 getgroups();
  
 

},[visible])
return(
  

 
 
  <div >
<Grid container spacing={2}>
<Grid item xs={9} >

<Card variant="outlined"  >
   <CardContent> 
    <div className="addgroupe">
   <Paper 
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'    }}
    >
    
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="البحث عن مجموعة"
        inputProps={{ 'aria-label': ' البحث عن مجموعة' }}
        onChange={e=>{
          
         setFilterModel({
            items: [
              {
                columnField: 'name',
                operatorValue: 'contains',
                value: e.target.value,
              },
            ],
          })
          
          setgroupenamefilter(e.target.value)}}
        value={ groupenamefilter}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <BsSearch />
      </IconButton>
     
    </Paper>
     </div>
     
            <DataGrid 
              filterModel={filterModel}
 
     density={"comfortable"}
       dir="rtl"
      localeText={arSD.components.MuiDataGrid.defaultProps.localeText} 
style={{height:"60vh"}}
        rows={groups }
        columns={columns}
        
        rowsPerPageOptions={[5,10,100,1000]}
        loading={ loadingdata}
      />
      </CardContent>
      <CardActions>
      <span style={{fontSize:"17px" ,margin:"10px"}}>      {visible=="TRUE"?  "اضهار المجموعات المخفية " :"اخفاء المجموعات المخفية" }</span>

      <Button className="eye" size="large" variant="contained" onClick={e=>{setvisible(visible=="TRUE"?"FALSE":"TRUE") }} > {visible=="TRUE"?  <AiFillEyeInvisible   />:<AiFillEye/>}</Button>
   </CardActions>
  
</Card>  
   </Grid>
 
   <Grid item xs={3}>  
   <Card variant="outlined">
    <CardHeader>
      
    </CardHeader>
   <CardContent className="addgroupe"> 
   <div className="tpg">
          اضافة مجموعة
        </div>
<TextField
 
required
label="اسم المجموعة"
id="filled-basic"
variant="outlined"
value={group ?group.name:""}
onChange={e=>{setgroup({...group,name:e.target.value})}}
 
/>
</CardContent>
<CardActions>
 
        <Button style={{ height:"50px",marginTop:"10px",backgroundColor:"#0065af "}} size="large" variant="contained" onClick={e=>addgroupe()} > حفظ</Button>
        <div style={{width:"20px"}}></div>
        <Button style={{ height:"50px",marginTop:"10px",backgroundColor:"#5b6b7b"}} size="large" variant="contained" onClick={e=>cancel()} > الغاء</Button>

      </CardActions>
</Card>  
<Card variant="outlined" className="Card1 Card">
   <CardContent> 
  <div className="dashicon">
    <IoIosPeople style={{ color:"#41c0e9"}} />
    <span>اجمالي المعتمرين</span>
    <b>{statistic.totoalmutamr}</b> 
    </div>
    <div>
  </div>
</CardContent>
 
</Card>  
<Card variant="outlined"  className="Card2 Card">
   <CardContent> 
  <div className="dashicon">
    <FaCcVisa  style={{ color:"#1cc3af"}}/>
    <span>اجمالي التأشيرات</span>
    <b>{statistic.totoalvisa}</b> 
    </div>
    <div>
  </div>
</CardContent>
 
</Card>  
<Card variant="outlined" className="Card3 Card">
   <CardContent> 
  <div className="dashicon">
    <BsFillPersonCheckFill style={{ color:"#0065af"}} />
    <span>اجمالي الموافقات</span>
    <b>{statistic.totalmouf9a}</b> 
    </div>
    <div>
  </div>
</CardContent>
 
</Card>  
</Grid>
</Grid>
 
<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {" هل انت متأكد من حذف المجموعة؟"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>الغاء</Button>
          <Button onClick={e=>{deletegroupe();  } } autoFocus>
            نعم
          </Button>
        </DialogActions>
      </Dialog>
    </div>
 
 
)
}
 