import request from "../configs";

// import { authHeader } from "./auth-header";
export default class AgencyService {
 
    constructor() {
       
    
      this.request = request;
     
      };
   
    
      getagency(filter) {
      let requestOptions =  {
        headers: {
     
          Authorization:
          "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI
           
        }
      };
       

 
      return this.request.get(JSON.parse(localStorage.getItem("user")).DATBASENAME+`/Settings`,requestOptions);
    }
 


    uploadFileToServeur =  (file) => {
      const url = 'http://upload.awslabs.tn/Upload.php';
     
      const headers = {
      
        'Content-Type': 'multipart/form-data',
         
      };

       

      // Extract the base64-encoded image data from the data URL
      const base64Image = file.split(",")[1];
      const formData = new FormData();
      formData.append('image', base64Image);
      formData.append("image_type", "jpg");
      
        return this.request.post(url, formData, { headers });
    

       
    };
  
    updateagency(body){
      let requestOptions =  {
        headers: {
     
          Authorization:
          "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI
           
        }
      };
      var body1=JSON.parse(localStorage.getItem("Agency"));
      if( !body1.logo.includes("http")){
        delete body1.logo;
      }else{
        body1.logo=[{
             
          "url": body1.logo,
        }]
      }
      var body2={"records": [
        {
      "id":JSON.parse(localStorage.getItem("Agencyid")),
          "fields": 
           
              body1 
             
          
         
        }
    ]
    }
     
        return this.request.patch(JSON.parse(localStorage.getItem("user")).DATBASENAME+`/Settings`,body2,requestOptions);
      }

    } 
    
    

   
   
 