import React, { Component } from "react";
 
 
import request from "../../configs";
import { ToastContainer, toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css';
import "./styles.scss";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import logo from "./logo2.png"
import backgroundimage from './bk.jfif'
import AgencyService from "../../services/Agency.services";
import { NotificationManager } from "react-notifications";
class Login extends Component {
   constructor(props){
    super()
    this.request=request;

    
  }
 
  state={
    email:"",
    password:"",
  }
     _agencyService=new AgencyService();
  gettoken(){
  
    
    this.request.post(`http://upload.awslabs.tn/api/auth.php`,{"username": this.state.email,"password": this.state.password}).then(res=>{
      
      if(res.data.Active!=1){

        NotificationManager.error('  خطاء في كلمة المرور او اسم المستخدم', '');
      }
     else{
 
        
        localStorage.setItem("user", JSON.stringify(res.data));
 
      
        this._agencyService.getagency().then(res2=>{
          console.log(res2)
          localStorage.setItem("Agency", JSON.stringify(res2.data.records[0].fields));
          localStorage.setItem("Agencyid", JSON.stringify(res2.data.records[0].id));
          this.props.history.push("/")
        }).catch(error=>{
     
        }); 

      }
      
      
       
   

    }).catch(res=>{ toast.error( "invalid_credentials", {
        position: toast.POSITION.TOP_RIGHT      }) })
   }
   
  render() {
    return (
      <div  dir="rtl" style={{display:"flex"}}>
        
<div style={{width:"40%"}}>

<div style={{padding:"10%",marginTop:"15%"}}>    
<div>
<div className="tpg2" > تسجيل الدخول</div>  
</div>
 
   <div>
          <TextField
   style={{width:"100%",margin:"5px"}}
          required
          label="اسم المستخدم"
          id="filled-basic"
          variant="outlined"
       
          onChange={e=>{this.setState({email:e.target.value})}}
        />
        </div>
       <div>
        
          <TextField
       style={{width:"100%",margin:"5px"}}
          label="كلمة المرور"
          id="basic-password-input"
          variant="outlined"
          type="password"
          autoComplete="current-password"
          onChange={e=>{this.setState({password:e.target.value}) }} 
        /> 
        
        </div>
              
        <div>
            <Button  className="loginbutton"  size="large" variant="contained"  onClick={()=> {this.gettoken();  }}>تسجيل الدخول</Button>
         </div>  
        
</div>

 
</div>
        
<div className="leftside">
  <div className="Mask">

  </div>
{/*<img className="logo" src={logo} style={{width:"350px"}}/>*/}
  <img className="zoom-in-out-box" src={backgroundimage} style={{width:"100%",height:"100vh"}}/> 
</div>
         </div>
    );
  }
}

export default Login;
