import request from "../configs";

// import { authHeader } from "./auth-header";
export default class grouppingService {
 
    constructor() {
       
    
      this.request = request;
     
      };
     
    getgroups(vis) {
      let requestOptions =  {
        headers: {
          Authorization:
           "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI
        }
      };
      let filterByFormula="";
       if(vis=="FALSE"){
        filterByFormula='?filterByFormula=SEARCH(Visible,"false")';
       }else{
        filterByFormula='?filterByFormula=SEARCH(Visible,"true")';
       }
 
      return this.request.get(JSON.parse(localStorage.getItem("user")).DATBASENAME+`/Groupes`+filterByFormula,requestOptions);
    }
    getgroupsx(x) {
      let requestOptions =  {
        headers: {
          Authorization:
            "bearer " +
            (localStorage.getItem("user") &&
              typeof localStorage.getItem("user") != "undefined" &&
              JSON.parse(localStorage.getItem("user")).token),
        }
      };
       

 
      return this.request.get(`/api/MuatamrGroup?Agancyid=`+x,requestOptions);
    }
    getgroupsbyid(id) {
      let requestOptions =  {
        headers: {
          Authorization:
            "bearer " +
            (localStorage.getItem("user") &&
              typeof localStorage.getItem("user") != "undefined" &&
              JSON.parse(localStorage.getItem("user")).token),
        }
      };
       
 
      return this.request.get(`/api/MuatamrGroup`+id,requestOptions);
    }
    deletegroup(id) {
      let requestOptions =  {
        headers: {
          Authorization:
        
         "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI,
        }
      };
       
 
      return this.request.delete(JSON.parse(localStorage.getItem("user")).DATBASENAME+"/Groupes/"+id,requestOptions);
    }
    Hide(id) {
      let requestOptions =  {
        headers: {
          Authorization:
            "bearer " +
            (localStorage.getItem("user") &&
              typeof localStorage.getItem("user") != "undefined" &&
              JSON.parse(localStorage.getItem("user")).token),
        }
      };
       
 
      return this.request.put(`/api/MuatamrGroup/setvisble/`+id,null,requestOptions);
    }



          
    addgroup(body){
       
      let requestOptions =  {
        headers: {
          Authorization:
      
         "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI,
        }
        
      };if(body.id!=""){
        var body1={"records": [
          {
            "id": body.id,
            "fields": {
              "Mutamrs":body.Mutamrs,
              "name":  body.name,
              "Visible": "true"
            
            }
          }
      ]
      
      }
      return this.request.put(JSON.parse(localStorage.getItem("user")).DATBASENAME+"/Groupes",body1,requestOptions);
      }else{
        var body1={"records": [
          {
        
            "fields": {
             
              "name":  body.name,
              "Visible": "true"
            
            }
          }
      ]
      }
      return this.request.post(JSON.parse(localStorage.getItem("user")).DATBASENAME+"/Groupes",body1,requestOptions);
      }
      
      
     
   
    }

   
   
  }