import { FormControl, InputLabel, Select,MenuItem, IconButton, Button, Grid, Avatar } from "@mui/material";
import { Container } from "@mui/system";
import { arSD, DataGrid } from "@mui/x-data-grid";
import React, { Component, useEffect, useState } from "react";
import { CgArrowsExchangeAlt } from "react-icons/cg";
 

import grouppingService from "../services/groupping.services";
import PelgrimsService from "../services/Pelgrims.services";
import { useHistory } from "react-router-dom";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import { Status } from "./status";
  
  export const   Exchangeselector =({ ...props }) => {
    const [filter, setfilter] = useState ();
    const [groupidList1, setgroupidList1] = useState ();
    const [groupidList2, setgroupidList2] = useState ();
    const [groupsList, setgroupsList] = useState ([]);
    const [loadingdata1, setloadingdata1] = useState (false);
    const [loadingdata2, setloadingdata2] = useState (false);
     
    const [mutamarsList1, setmutamarsList1] = useState ([]);
    const [mutamarsList2, setmutamarsList2] = useState ([]);
 
    const _grouppingService = new grouppingService();
    const _pelgrimsService = new PelgrimsService();
    function getgroups(x){
        _grouppingService.getgroups(x).then(res=>{
        
          setgroupsList(res.data.records);
      
        }).catch(res=>{
          NotificationManager.error('يرجى التحقق من البينات', '');
        }

        )
    }
    function getpelgrimsList1(x){
     
      setmutamarsList1([]);
 
         
      _pelgrimsService.getmutbygroup(groupsList[x].fields.ID).then(res=>{
   

   res.data.records.map(x2=>{
     setmutamarsList1((prevArray) => [...prevArray,  x2]);
   
     setloadingdata1(false);

   })
 
      }
  
      )

     

       

       
    }
 

    function getpelgrimsList2(x){
      
     
     
      setmutamarsList2([]);
 
         
             _pelgrimsService.getmutbygroup(groupsList[x].fields.ID).then(res=>{
          
      
          res.data.records.map(x2=>{
            setmutamarsList2((prevArray) => [...prevArray,  x2]);
          
            setloadingdata2(false);
       
          })
        
             }
         
             )
      
    }
 

  function exchangeall(x){
    if(groupidList2!=groupidList1){

    
       mutamarsList1.map(x=>{
          x.groupingId=groupidList2
        _pelgrimsService.addMuatamr(x).then(res=>{
          NotificationManager.success('تم التحويل بنجاح بنجاح', '');
          getpelgrimsList1(groupidList1)
          getpelgrimsList2(groupidList2)
      }).catch(res=>{
        NotificationManager.error('يرجى التحقق من البينات', '');
      }
  
      )
       }


       )
      
  }else{
    NotificationManager.error('لايمكن تحويل المعتمر في نفس المجموعة'  , '');
  }
    }
    function exchangeall2(x){
      if(groupidList2!=groupidList1){
  
      
         mutamarsList2.map(x=>{
            x.groupingId=groupidList1
          _pelgrimsService.addMuatamr(x).then(res=>{
            NotificationManager.success('تم التحويل بنجاح بنجاح', '');
            getpelgrimsList1(groupidList1)
            getpelgrimsList2(groupidList2)
         getgroups()
         setloadingdata1(true);
         setloadingdata2(true);
        }).catch(res=>{
          NotificationManager.error('يرجى التحقق من البينات', '');
        }
    
        )
         }
  
  
         )
        
    }else{
      NotificationManager.error('لايمكن تحويل المعتمر في نفس المجموعة'  , '');
    }
      }
    function exchange(x,id){
      
    if(groupidList2!=groupidList1){

    
       if(x.groupingId==groupidList1){
        x.groupingId=groupidList2
       }else{
        x.groupingId=groupidList1
       }
      _pelgrimsService.exchangeMuatamr(x,groupsList[groupidList2].id,id).then(res=>{
        NotificationManager.success('تم التحويل بنجاح بنجاح', '');
        getpelgrimsList1(groupidList1)
        getpelgrimsList2(groupidList2)
     getgroups()
     setloadingdata1(true);
     setloadingdata2(true);
   
        
    }).catch(res=>{
      NotificationManager.error('يرجى التحقق من البينات', '');
    }

    )
  }else{
    NotificationManager.error('لايمكن تحويل المعتمر في نفس المجموعة'  , '');
  }
    }
    
    const handleChange2 = (event) => {
      setmutamarsList1([]);
        setgroupidList1(event.target.value);
        if(groupsList[event.target.value].fields.Mutamrs!== undefined){
        getpelgrimsList1(event.target.value)
          setloadingdata1(true);
        }

        
    };
    const handleChange3 = (event) => {
      setmutamarsList2([]);
      setgroupidList2(event.target.value);
      if(groupsList[event.target.value].fields.Mutamrs!== undefined){
      getpelgrimsList2(event.target.value)
      setloadingdata2(true);
      }
      
  };
    const handleChange = (event) => {
        
        setfilter(event.target.value);
    };

    const columns = [
     
      { field: 'arabicName ', headerName: 'الإسم الكامل ', width: 250, renderCell: (parm) => {
        return (
          <div style={{display:"flex"}}>
          
          <Avatar sx={{ width: 50, height: 50 }}  src={parm.row.fields.pilgrimPicturePath!=null?parm.row.fields.pilgrimPicturePath[0].thumbnails.small.url:""}  ></Avatar>
      
       
          <div style={{display:"flex",flexDirection:"column" ,marginTop:"5px"}}>
            <span className="namestyle">
              {parm.row.fields.arabicName+" "+ parm.row.fields.arabicLastName}
              </span>
<span className="namestyle">
{parm.row.fields.firstName+" "+ parm.row.fields.lastName}
</span>
          </div>
          </div>
        )} },
      
 
      
      { field: 'passportNumber', headerName: 'رقم الجواز', width: 120  ,valueGetter: params => params.row.fields.passportNumber },
      { field: 'notes', headerName: 'نتيجة الارسال', width: 200 ,valueGetter: params => params.row.fields.notes },
        {
          field: "actions",
          headerName: "العمليات",
          sortable: false,
          width: 140,
          disableClickEventBubbling: true,
          renderCell: (parm) => {
            return (
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ cursor: "pointer" }}
              >
                <IconButton className="addicon" aria-label="addtolist" size="large" onClick={e=>exchange(parm.row.fields,parm.row.id)}>
        <CgArrowsExchangeAlt   />
      </IconButton>
      
  
              </div>
            );
          }
        }
      ];
      const columns2 = [
     
        { field: 'arabicName ', headerName: 'الإسم الكامل ', width: 250, renderCell: (parm) => {
          return (
            <div style={{display:"flex"}}>
            
            <Avatar sx={{ width: 50, height: 50 }}  src={parm.row.fields.pilgrimPicturePath!=null?parm.row.fields.pilgrimPicturePath[0].thumbnails.small.url:""}  ></Avatar>
        
         
            <div style={{display:"flex",flexDirection:"column" ,marginTop:"5px"}}>
              <span className="namestyle">
                {parm.row.fields.arabicName+" "+ parm.row.fields.arabicLastName}
                </span>
  <span className="namestyle">
  {parm.row.fields.firstName+" "+ parm.row.fields.lastName}
  </span>
            </div>
            </div>
          )} },
        
   
        
        { field: 'passportNumber', headerName: 'رقم الجواز', width: 120  ,valueGetter: params => params.row.fields.passportNumber },
        { field: 'notes', headerName: 'نتيجة الارسال', width: 200 ,valueGetter: params => params.row.fields.notes },
          
        ];
  useEffect(() => {
     getgroups();
  },[] )
  return(
    <div>
    <Grid  container spacing="5">
    <Grid item xs={5.5} sm={5.5} md={5.5} >
    <div>
     
 {/* <FormControl sx={{ m: 1, width: 200 }}>
        <InputLabel id="demo-simple-select-autowidth-label">بحث حسب  </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={filter}
      
          onChange={handleChange}
          
          label="بحث حسب"
        >
          <MenuItem value="">
            <em>  </em>
          </MenuItem>
          <MenuItem value={1}>بحث حسب المجموعة</MenuItem>
          <MenuItem value={2}>بحث حسب رقم الجواز</MenuItem>
          <MenuItem value={3}>بحث حسب اسم المعتمر</MenuItem>
        </Select>
      </FormControl> */}
      <FormControl sx={{ m: 1, width: 500 }}>
        <InputLabel id="demo-simple-select-autowidth-label"> المجموعة  </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={groupidList1}
      disabled={loadingdata1}
          onChange={handleChange2}
          
          label=" اسم المجموعة"
        >
          <MenuItem value="">
            <em>  </em>
          </MenuItem>
          {
            groupsList.map( (x,index) =>{
              return <MenuItem value={index}>
                <div dir="RTL"> 
<table style={{fontSize:"14px"}}>
  <tr>
  <td style={{fontSize:"20px"}}>  {x.fields.name+" "}   </td>

  <td><Status  data={{"total":x.fields.totalmutamr,"visa":x.fields.totalvisa,"mouwaf9a":x.fields.totalmouwaf9a}}/> </td>
 
  
  </tr>
</table>


</div>
                
              
                
                </MenuItem>


          })
          }
 
        </Select>
      </FormControl>
      <FormControl>
      </FormControl>
    </div>
     
    
                  <DataGrid 
 density={"comfortable"}
       dir="rtl"
      localeText={arSD.components.MuiDataGrid.defaultProps.localeText} 
style={{height:"70vh" }}
        rows={mutamarsList1}
        columns={columns}
        rowsPerPageOptions={[5,10,100,1000]}
        loading={loadingdata1}
        disabled={loadingdata1}
      />
     
      
      </Grid>
      <Grid item>
        <div style={{height:"40vh"}}></div>
      <IconButton className="exchangeall" aria-label="addtolist" size="large" onClick={e=>exchangeall()}>
        <FaArrowAltCircleLeft   />
      </IconButton>
      <br></br>
      <IconButton className="exchangeall" aria-label="addtolist" size="large" onClick={e=>exchangeall2()}>
        <FaArrowAltCircleRight   />
      </IconButton>
      </Grid>
      <Grid item xs={5.5} sm={5.5} md={5.5} >

     
      
      <div>
     
     {/* <FormControl sx={{ m: 1, width: 200 }}>
            <InputLabel id="demo-simple-select-autowidth-label">بحث حسب  </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={filter}
          
              onChange={handleChange}
              
              label="بحث حسب"
            >
              <MenuItem value="">
                <em>  </em>
              </MenuItem>
              <MenuItem value={1}>بحث حسب المجموعة</MenuItem>
              <MenuItem value={2}>بحث حسب رقم الجواز</MenuItem>
              <MenuItem value={3}>بحث حسب اسم المعتمر</MenuItem>
            </Select>
          </FormControl> */}
          <FormControl sx={{ m: 1, width: 500 }}>
            <InputLabel id="demo-simple-select-autowidth-label"> المجموعة  </InputLabel>
            <Select
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              value={groupidList2}
              disabled={loadingdata2}
              onChange={handleChange3}
              
              label=" اسم المجموعة"
            >
              <MenuItem value="">
                <em>  </em>
              </MenuItem>
              {
                groupsList.map( (x,index) =>{
                  return <MenuItem value={index}>
                    <div dir="RTL"> 
    <table style={{fontSize:"14px"}}>
      <tr>
      <td style={{fontSize:"20px"}}>  {x.fields.name+" "}   </td>
    
      <td><Status  data={{"total":x.fields.totalmutamr,"visa":x.fields.totalvisa,"mouwaf9a":x.fields.totalmouwaf9a}}/> </td>
     
      
      </tr>
    </table>
    
    
    </div>
                    
                  
                    
                    </MenuItem>
    
    
              })
              }
     
            </Select>
          </FormControl>
          <FormControl>
          </FormControl>
        </div>
         
                  <DataGrid 
 density={"comfortable"}
       dir="rtl"
      localeText={arSD.components.MuiDataGrid.defaultProps.localeText} 
style={{height:"70vh" }}
            rows={mutamarsList2}
            columns={columns2}
           loading={loadingdata2}
           disabled={loadingdata2}
            rowsPerPageOptions={[5,10,100,1000]}
            
          />
       
       </Grid>
      </Grid>
  

    </div>

     
    
  )
  };
