import { FormControl, InputLabel, Select,MenuItem, IconButton, Button } from "@mui/material";
import { Container } from "@mui/system";
import { arSD, DataGrid } from "@mui/x-data-grid";
import React, { Component, useEffect, useState } from "react";
import { MdDelete  ,  Forever, MdEdit } from "react-icons/md";
import grouppingService from "../services/groupping.services";
import PelgrimsService from "../services/Pelgrims.services";
import { useHistory } from "react-router-dom";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import * as XLSX from "xlsx";
import { set } from "date-fns";
import loadingicon from "./loading.gif"
  export const   Importwakilselector =({ ...props }) => {
    const [filter, setfilter] = useState ();
    const [groupid, setgroupid] = useState ();
    const [groups, setgroups] = useState ([]);
    const [mutamars, setmutamars] = useState (null);
    const [loading, setloading] = useState (false);
    const history = useHistory();
    const _grouppingService = new grouppingService();
    const _pelgrimsService = new PelgrimsService();
 
     function Updatewakilcode(){
      setloading(true)
      _pelgrimsService.Updatewakilcode(mutamars).then(res=>{
        NotificationManager.success('تم الحفظ '+res.data+'بنجاح', '');
        setloading(false)
      }).catch(error=>{
        NotificationManager.error('يرجى التحقق من الملف', '');
        setloading(false)
      })
     }
 
    const onChange = (e) => {
      const [file] = e.target.files;
      const reader = new FileReader();
  
      reader.onload = (evt) => {
         
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        const wsname = wb.SheetNames[2];
        let  el22=[];;
        for(var element in wb.Sheets){
          el22.push(wb.Sheets[element]);
        }
       
        const arraymoufa=[];
        const arraypassportnumber=[];
        const arrayname=[];
        let localarray=[];
         
 for(var  el in el22){


      for (var prop in el22[el]) {
        console.log(`obj.${prop} = ${el22[el][prop].v}`);
        var validate= prop.replace(prop[0],"D");
        try{


       if(el22[el][validate].v.includes("أنثى")||el22[el][validate].v.includes("ذكر")){
        
       if(prop.includes("B") ){
        arraymoufa.push(el22[el][prop].v+"");
       }
       if( prop.includes("G") ){
        arraypassportnumber.push(el22[el][prop].v);
       }
       if(prop.includes("C")){
        arrayname.push(el22[el][prop].v);
       }
      }}catch{}
      }
    }
       for(let i=0;i<arraymoufa.length;i++){
     

  
        localarray.push({"id":i,"namePelgrim":""+arrayname[i],"passportnumber":""+arraypassportnumber[i],"wakilNumber":""+arraymoufa[i]})
    
     console.log(arraymoufa[i]+","+arrayname[i]+","+arraypassportnumber[i]);

       }
     
       setmutamars( localarray );

        
      };
      reader.readAsBinaryString(file);
    };
 
    const columns = [
    
        { field: 'namePelgrim', headerName: 'الاسم  ', width: 200 },
        { field: 'passportnumber', headerName: 'رقم الجواز', width: 150 },
 
        { field: 'wakilNumber', headerName: ' رقم الطلب', width: 150 } 
      ];
  useEffect(() => {
   
  },[])
  return(
    <div>
   

    
   
    <div>
    <Container fixed>
    <Button className="addpelgrim"  disabled={loading}   component="label"  variant="contained"   >
                  <input hidden type="file" onChange={onChange}  />
تحميل ملف الموافقات      </Button>
                  <DataGrid 
 density={"comfortable"}
       dir="rtl"
      localeText={arSD.components.MuiDataGrid.defaultProps.localeText} 
      disabled={loading}  
style={{height:"60vh"}}
        rows={!mutamars?[]:mutamars}
        columns={columns}
        rowsPerPageOptions={[5,10,100,1000]}
        
      />
      {loading? <img src={loadingicon} style={{height:"35px"}}/>:
          <Button style={{ height:"50px",marginTop:"10px",backgroundColor:"#0a8925"}} size="large" variant="contained"    onClick={e=>Updatewakilcode()}>
            
            
            حفظ
            
            </Button>
          }
      </Container>
      </div>

    </div>

     
    
  )
  };
