import { FormControl, InputLabel, Select,MenuItem, IconButton, Button, TextField } from "@mui/material";
import { Container } from "@mui/system";
import { arSD, DataGrid } from "@mui/x-data-grid";
import React, { Component, useEffect, useState } from "react";
import { MdDelete  ,  Forever, MdEdit } from "react-icons/md";
import grouppingService from "../services/groupping.services";
import PelgrimsService from "../services/Pelgrims.services";
import { useHistory } from "react-router-dom";
import {NotificationContainer, NotificationManager} from 'react-notifications';
  
  export const   Payment =({ ...props }) => {
    const [filter, setfilter] = useState ();
    const [groupid, setgroupid] = useState ();
    const [groups, setgroups] = useState ([]);
    const [mutamars, setmutamars] = useState ([]);
    const history = useHistory();
    const _grouppingService = new grouppingService();
    const _pelgrimsService = new PelgrimsService();
    function getgroups(x){
        _grouppingService.getgroups(x).then(res=>{
        
          setgroups(res.data);
      
        }).catch(res=>{
          NotificationManager.error('يرجى التحقق من البينات', '');
        }

        )
    }
    function getpelgrims(x){
        _pelgrimsService.GetByGroupName(x).then(res=>{
        
            setmutamars(res.data);
        })
    }
    function Delete(x){
      _pelgrimsService.Delete(x).then(res=>{
        getpelgrims(groupid);
        NotificationManager.success('تم الحذف بنجاح', '');
    }).catch(res=>{
      NotificationManager.error('يرجى التحقق من البينات', '');
    }

    )
    }
    
    const handleChange2 = (event) => {
        
        setgroupid(event.target.value);
        getpelgrims(event.target.value)
        
    };
    const handleChange = (event) => {
        
        setfilter(event.target.value);
    };

    const columns = [
        { field: 'pilgrimPicturePath', headerName: 'صورة المعتمر ', width: 100 ,height:100,
        renderCell: (parm) => {
          return (
            <div>
             <img src={parm.row.pilgrimPicturePath} style={{width:"50px"}}></img>
   
            </div>
          );
        }
      },
      
    
      ,
        { field: 'arabicName', headerName: 'الاسم العربي', width: 100 },
        { field: 'passportnumber', headerName: ' رقم الجواز', width: 100 },

        { field: 'MontantTotal',headerName: 'المبلغ الاجمالي', width: 150 ,height:250,
        renderCell: (parm) => {
          return (
            <TextField
      
            id="filled-basic"
            variant="standard"
            onChange={e=>{ parm.row.MontantTotal=e.target.value }}
            value={parm.row.MontantTotal}
         
            />
          );
        }
      },
      { field: 'avance',headerName: ' المقدم  ', width: 150 ,height:250,
        renderCell: (parm) => {
          return (
            <TextField
       
            id="filled-basic"
            variant="standard"
            onChange={e=>{  parm.row.avance=e.target.value}}
            value={parm.row.avance}
         
            />
          );
        }
      },
      { field: 'payment',headerName: ' حالة الخلاص  ', width: 150 ,height:250,
        renderCell: (parm) => {
          return (
          
      
       Number(parm.row.MontantTotal)-Number(parm.row.avance)==0?
      <Button style={{ height:"40px",backgroundColor:"green"}} size="large" variant="contained" onClick={ e=>{} }>   مدفوع</Button>
      : <Button style={{ height:"40px",backgroundColor:"red"}} size="large" variant="contained" onClick={ e=>{} }>  غير مكتمل  </Button>
  
            
          );
          }
      },
         
          
       
        {
          field: "actions",
          headerName: "العمليات",
          sortable: false,
          width: 140,
          disableClickEventBubbling: true,
          renderCell: (parm) => {
            return (
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ cursor: "pointer" }}
              >
        
      <IconButton aria-label="addtolist" size="large"     onClick={  e=>history.push("/Pelgrims/Addpelgrim/"+groupid+"/"+parm.id)}  >
        <MdEdit   />
      </IconButton>
  
              </div>
            );
          }
        }
      ];
  useEffect(() => {
    getgroups(groupid);
  },[])
  return(
    <div>
   
    <div>
 <FormControl sx={{ m: 1, width: 150 }}>
        <InputLabel id="demo-simple-select-autowidth-label">بحث حسب  </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={filter}
      
          onChange={handleChange}
          
          label="بحث حسب"
        >
          <MenuItem value="">
            <em>  </em>
          </MenuItem>
          <MenuItem value={1}>بحث حسب المجموعة</MenuItem>
          <MenuItem value={2}>بحث حسب رقم الجواز</MenuItem>
          <MenuItem value={3}>بحث حسب اسم المعتمر</MenuItem>
        </Select>
      </FormControl>
      <FormControl sx={{ m: 1, width: 150 }}>
        <InputLabel id="demo-simple-select-autowidth-label"> المجموعة  </InputLabel>
        <Select
          labelId="demo-simple-select-autowidth-label"
          id="demo-simple-select-autowidth"
          value={groupid}
      
          onChange={handleChange2}
          
          label=" اسم المجموعة"
        >
          <MenuItem value="">
            <em>  </em>
          </MenuItem>
          {
            groups.map( x=>{
                return <MenuItem value={x.id}>{x.name}</MenuItem>


            })
          }
 
        </Select>
      </FormControl>
      <FormControl>
         </FormControl>
    </div>
    <div>
    <Container fixed>
                  <DataGrid 
 density={"comfortable"}
       dir="rtl"
      localeText={arSD.components.MuiDataGrid.defaultProps.localeText} 
style={{height:"80vh"}}
        rows={mutamars}
        columns={columns}
        rowsPerPageOptions={[5,10,100,1000]}
        
      />
      </Container>
    </div>

    </div>

     
    
  )
  };
