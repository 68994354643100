import request from "../configs";

// import { authHeader } from "./auth-header";
export default class PelgrimsService {
 
    constructor() {
       
    
      this.request = request;
     
      };
   
      GetByMutamrsbygroupeid(groupid) {
        let requestOptions =  {
          headers: {
            Authorization:
           "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI,
          }
        };
         
        let filterByFormula='?filterByFormula=SEARCH(groupingId,"'+groupid+'")';
   
        return this.request.get(JSON.parse(localStorage.getItem("user")).DATBASENAME+ '/Mutamrs/'+filterByFormula,requestOptions);

       
      }
      GetByGroupNamepic(mutamrid) {
        let requestOptions =  {
          headers: {
            Authorization:
           "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI,
          }
        };
         
      
   
        return this.request.get(JSON.parse(localStorage.getItem("user")).DATBASENAME+ '/Mutamrs/'+mutamrid,requestOptions);

       
      }
      GetByGroupName(groupeid) {
        let requestOptions =  {
          headers: {
            Authorization:
              "bearer " +
              (localStorage.getItem("user") &&
                typeof localStorage.getItem("user") != "undefined" &&
                JSON.parse(localStorage.getItem("user")).token),
          }
        };
         
  
   
        return this.request.get(`/api/Muatamr/GetByGroupName/`+groupeid,requestOptions);

       
      }
      getPelgrims(id) {
        let requestOptions =  {
          headers: {
            Authorization:
              "bearer " +
              (localStorage.getItem("user") &&
                typeof localStorage.getItem("user") != "undefined" &&
                JSON.parse(localStorage.getItem("user")).token),
          }
        };
         
  
   
        return this.request.get(`/api/Muatamr/`+id,requestOptions);

       
      }
      getmutbygroup(x) {
        let requestOptions =  {
          headers: {
            Authorization:
           "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI,
          }
        };
 
        var filterby="FIND(RECORDID,'"+x+"')";
       
        //var filterby="OR(firstName='"+x+"',lastName='"+x+"',passportNumber='"+x+"',arabicName='"+x+"',arabicLastName='"+x+"')";
        //var filterby="OR(OR(FIND(firstName,'"+x+"'),FIND(lastName,'"+x+"')),FIND(passportNumber,'"+x+"'),OR(FIND(arabicName,'"+x+"'),FIND(arabicLastName,'"+x+"')))";
        return this.request.get(JSON.parse(localStorage.getItem("user")).DATBASENAME+ '/Mutamrs/?filterByFormula='+filterby,requestOptions);

       
      }
      getbytext2(x) {
        let requestOptions =  {
          headers: {
            Authorization:
           "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI,
          }
        };
         
      
        //var filterby="OR(firstName='"+x+"',lastName='"+x+"',passportNumber='"+x+"',arabicName='"+x+"',arabicLastName='"+x+"')";
        var filterby="OR(OR(FIND(firstName,'"+x+"'),FIND(lastName,'"+x+"')),FIND(passportNumber,'"+x+"'),OR(FIND(arabicName,'"+x+"'),FIND(arabicLastName,'"+x+"')))";
        return this.request.get(JSON.parse(localStorage.getItem("user")).DATBASENAME+ '/Mutamrs/?filterByFormula='+filterby,requestOptions);

       
      }
      Updatewakilcode(id,erreur){
        let requestOptions =  {
          headers: {
            Authorization:
           "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI
          }
        };
        
     
        var body2={"records": [
          {
             "id":id,
            "fields": {
              "notes":erreur,
          
             
        }}]
        }
     
        return this.request.patch(JSON.parse(localStorage.getItem("user")).DATBASENAME+"/Mutamrs",body2,requestOptions);
       
    
      }
  
      archive(body){
        let requestOptions =  {
          headers: {
            Authorization:
              "bearer " +
              (localStorage.getItem("user") &&
                typeof localStorage.getItem("user") != "undefined" &&
                JSON.parse(localStorage.getItem("user")).token),
          }
        };
        
    
 
          return this.request.post("/api/Muatamr/archive",body,requestOptions);
        
    
      }
      Updatetraveldata(body,id){
        let requestOptions =  {
          headers: {
            Authorization:
           "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI
          }
        };
        
     

        var body2={"records": [
          {
             "id":id,
            "fields": {
              "dateArrival":body.dateArrival,
              "flightNumber":body.flightNumber,
              "stayPeriod":body.stayPeriod,
              "transport":body.transport,
              "wayPort":body.wayPort

        }}]
        }
     
        return this.request.patch(JSON.parse(localStorage.getItem("user")).DATBASENAME+"/Mutamrs",body2,requestOptions);
        
    
      }
      Updatevisa(body,id){
        let requestOptions =  {
          headers: {
            Authorization:
           "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI
          }
        };
        
     
        var body2={"records": [
          {
             "id":id,
            "fields": {
              "passportNumber":body.passportNumber,
              "visapicPath": [{
             
                "url": body.VisapicPath,
              }],
              "visaNumber":body.visaNumber
        }}]
        }
     
        return this.request.patch(JSON.parse(localStorage.getItem("user")).DATBASENAME+"/Mutamrs",body2,requestOptions);
       
    
      }
      addMuatamr(body,id){
         
        let requestOptions =  {
          headers: {
            Authorization:
           "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI
          }
        };
        
       
         
     if(id!=""){
      var body2= {"records": [
        {
           "id":id,
          "fields": {
            "groupingId": [
              body.groupingId
            ],
            "gender":  body.gender,
            "firstName": body.firstName ,
            "lastName":  body.lastName,
            "arabicName":  body.arabicName,
            "arabicLastName":  body.arabicLastName,
            "mrz": body.mrz,
            "passportNumber": body.passportNumber,
            "passportDelieveryDate": body.passportDelieveryDate,
            "passportExpiryDate":  body.passportExpiryDate,
            "passportDelieveryCity":  body.passportDelieveryCity,
            "passportDeliveryCountry":  body.passportDeliveryCountry,
            "nationalityCountry":  body.nationalityCountry,
            "birthDate":  body.birthDate,
            "birthCountry": body.birthCountry,
            "birthCity":  body.birthCity,
            "job":  body.job,
            "civilState":  body.civilState,
            "educationLevel":  body.educationLevel,
            "healthStatus":  body.healthStatus,
            "hasMuhram": body.hasMuhram,
            "addressCountryId": body.addressCountryId,
            "transport":  body.transport,
            "wayPort":  body.wayPort,
            "dateArrival":  body.dateArrival,
            "stayPeriod":  body.stayPeriod,
            "flightNumber":  body.flightNumber,
            "notes": body.notes,
            "pilgrimCode":  body.pilgrimCode,
            "Title1":  body.Title1,
            "createdDate":  body.createdDate,
            "updatedDate":  body.updatedDate,
            "pilgrimPicturePath":[{
             
              "url": body.pilgrimPicturePath,
            }],
            "passportPicturePath":[{
             
              "url": body.passportPicturePath,
            }]
             
          }
        }
      ]
    }
    if( !body.passportPicturePath.includes("http")){
      delete body2.records[0].fields.passportPicturePath;
    }
    if( !body.pilgrimPicturePath.includes("http")){
      delete body2.records[0].fields.pilgrimPicturePath;
    }
      return this.request.patch(JSON.parse(localStorage.getItem("user")).DATBASENAME+"/Mutamrs",body2,requestOptions);
     }else{


      var body2= {"records": [
        {
           
          "fields": {
            "groupingId": [
              body.groupingId
            ],
            "gender":  body.gender,
            "firstName": body.firstName ,
            "lastName":  body.lastName,
            "arabicName":  body.arabicName,
            "arabicLastName":  body.arabicLastName,
            "mrz": body.mrz,
            "passportNumber": body.passportNumber,
            "passportDelieveryDate": body.passportDelieveryDate,
            "passportExpiryDate":  body.passportExpiryDate,
            "passportDelieveryCity":  body.passportDelieveryCity,
            "passportDeliveryCountry":  body.passportDeliveryCountry,
            "nationalityCountry":  body.nationalityCountry,
            "birthDate":  body.birthDate,
            "birthCountry": body.birthCountry,
            "birthCity":  body.birthCity,
            "job":  body.job,
            "civilState":  body.civilState,
            "educationLevel":  body.educationLevel,
            "healthStatus":  body.healthStatus,
            "hasMuhram": body.hasMuhram,
            "addressCountryId": body.addressCountryId,
            "transport":  body.transport,
            "wayPort":  body.wayPort,
            "dateArrival":  body.dateArrival,
            "stayPeriod":  body.stayPeriod,
            "flightNumber":  body.flightNumber,
            "notes": body.notes,
            "pilgrimCode":  body.pilgrimCode,
            "Title1":  body.Title1,
            "createdDate":  body.createdDate,
            "updatedDate":  body.updatedDate,
            "pilgrimPicturePath":[{
             
              "url": body.pilgrimPicturePath,
            }],
            "passportPicturePath":[{
             
              "url": body.passportPicturePath,
            }]
             
          }
        }
      ]
    }
      return this.request.post(JSON.parse(localStorage.getItem("user")).DATBASENAME+"/Mutamrs",body2,requestOptions);
     }
       
       
    
      }
      exchangeMuatamr(body,id,id2){
        let requestOptions =  {
          headers: {
            Authorization:
           "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI
          }
        };
     
          var body2= {"records": [
            {
               "id":id2,
              "fields": {
                "groupingId": [
                 id
                ],
           
            
                 
              }
            }
          ]
        }
      
          return this.request.patch(JSON.parse(localStorage.getItem("user")).DATBASENAME+"/Mutamrs",body2,requestOptions);
       
    
      }

      Delete(body){
        let requestOptions =  {
          headers: {
            Authorization:
        
           "Bearer "+JSON.parse(localStorage.getItem("user")).AIRTABLEAPI,
          }
        };
   
          return this.request.delete(JSON.parse(localStorage.getItem("user")).DATBASENAME+"/Mutamrs/"+body.id,requestOptions)
       
    
      }


        uploadFileToServeur =  (file) => {
        const url = 'http://upload.awslabs.tn/Upload.php';
       
        const headers = {
        
          'Content-Type': 'multipart/form-data',
           
        };
  
         
 
        // Extract the base64-encoded image data from the data URL
        const base64Image = file.split(",")[1];
        const formData = new FormData();
        formData.append('image', base64Image);
        formData.append("image_type", "jpg");
        
          return this.request.post(url, formData, { headers });
      
 
         
      };

  }