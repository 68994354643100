 
import { IconButton, Tooltip } from "@mui/material";
import React, { Component, useEffect, useState } from "react";
import { FaCcVisa, FaClipboardList, FaIdBadge, FaPassport } from "react-icons/fa";
 
 
 
  
  export const   Print =({setindex} ) => {
  const[active,setactive]=useState(1)
  
  return(
    <div style={{width:"90%",alignItems:"center",justifyContent:"space-between", display:"flex" ,height: "74px"   ,position:"relative"} }>
  <div className="tpg">نوع التقرير</div>
   <div>
 
     <Tooltip title="بطاقة المعتمر">
     <IconButton style={active==1?{border: "1px solid #5a5a5a78", backgroundColor:"#0065af" ,color:"white",fontSize:"30px"}:{border: "1px solid #5a5a5a78", backgroundColor:"white" ,color:"#5a6b7c"}} onClick={e=>{setindex(1);setactive(1);}}> <FaIdBadge/> </IconButton>
   </Tooltip>
  </div>
   <div> 
    <Tooltip title="صور الجوازات">
     <IconButton   style={active==2?{border: "1px solid #5a5a5a78", backgroundColor:"#0065af" ,color:"white",fontSize:"30px"}:{border: "1px solid #5a5a5a78", backgroundColor:"white" ,color:"#5a6b7c"}} onClick={e=>{setindex(2);setactive(2);}}> <FaPassport/> </IconButton>
     </Tooltip>
  </div>
   <div> 
    <Tooltip title="قائمة المعتمرين بالاجنبي ">
     <IconButton  style={active==3?{border: "1px solid #5a5a5a78", backgroundColor:"#0065af" ,color:"white",fontSize:"30px"}:{border: "1px solid #5a5a5a78", backgroundColor:"white" ,color:"#5a6b7c"}}  onClick={e=>{setindex(3);setactive(3);}}> <FaClipboardList/> </IconButton>
     </Tooltip>
  </div>
   <div>
     <Tooltip title="قائمة المعتمرين بالعربي ">
     <IconButton   style={active==4?{border: "1px solid #5a5a5a78", backgroundColor:"#0065af" ,color:"white",fontSize:"30px"}:{border: "1px solid #5a5a5a78", backgroundColor:"white" ,color:"#5a6b7c"}} onClick={e=>{setindex(4);setactive(4);}}> <FaClipboardList/> </IconButton>
     </Tooltip>
  </div>
   <div>
    <Tooltip title="صور التأشيرات">
     <IconButton  style={active==5?{border: "1px solid #5a5a5a78", backgroundColor:"#0065af" ,color:"white",fontSize:"30px"}:{border: "1px solid #5a5a5a78", backgroundColor:"white" ,color:"#5a6b7c"}}  onClick={e=>{setindex(5);setactive(5);}}> <FaCcVisa/> </IconButton> 
   
     </Tooltip>
     </div>
  </div>
    
  )
  };
