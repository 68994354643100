import React, { useEffect, useState } from "react";
  
 import AgencyService from "../../services/Agency.services";
 import { arSD, DataGrid } from '@mui/x-data-grid';
import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, Card, CardActions, CardContent, FormControl, Grid, IconButton, InputBase, InputLabel, MenuItem, Paper, Select, Switch, TextField, Typography } from "@mui/material";
import countrylist from "../../data/country.json"
import embasylist from "../../data/embasy.json"
import scanner from "../../data/scanner.json"
import wakil from "../../data/walik.json"
 
import {
  MdDelete  ,  Forever,
  MdEdit,
  MdPhotoCamera,
  MdExpandMore
} from "react-icons/md";
import moment from "moment";
import { display } from "@mui/system";
import { BsSearch } from "react-icons/bs";
import NotificationManager from "react-notifications/lib/NotificationManager";
import UsersService from "../../services/Users.services";
import { Modal } from "react-bootstrap";
import { IoMdAdd } from "react-icons/io";
 
 
 
export default function Users({ ...props }) {
const[Agency,setAgency]=useState([]);
const[user,setuser]=useState({userName:"",password:"",email:""});
const[openadd,setopenadd]=useState(false);
const[openedit,setopenedit]=useState(false);

const[users,setusers]=useState([]);
const label = { inputProps: { 'aria-label': 'Switch demo' } };
const[agency,setagency]=useState({
  
  adresse:"",
  agancyNameAr:"",
  agancyNameFrr:"",
  city:"",
  country:null,
  createdBy:"",
  logo:"",
  phone:"",
  scanner:1,
  updatedBy:"",
  userId:0,
  wakil:1,
  wakilUser:"",
  wakilpassword:"",

  updatedDate: null
});
 
 function resetagancy(){
   
  setuser( {userName:"",password:"",email:""});
  setopenedit(false)
 }
const _agencyService = new AgencyService();
const _usersService = new UsersService();
function password_validate   (password) {
  var re = {
      capital: /(?=.*[A-Z])/,
      length: /(?=.{7,40}$)/,
      specialChar: /[ -\/:-@\[-\`{-~]/,
      digit: /(?=.*[0-9])/,
  };
  return (
      re.capital.test(password) &&
      re.length.test(password) &&
      re.specialChar.test(password) &&
      re.digit.test(password)
  );
};
 

function updatepassword(){
  
  if(!password_validate(user.password)){
    
    NotificationManager.error('يرجى التحقق من كلمة السر', '');
  }else{

 
    _usersService.updatepassword( user).then(res=>{
      setopenedit(false)
    getagency(1);
   console.log(res.data);
   NotificationManager.success('تم الحفظ بنجاح', '');

  
  
  }).catch(res=>{
    NotificationManager.error('يرجى التحقق من البينات', '');
  }

  )
}
}
function addagency(){
  
  if(!password_validate(user.password)){
    
    NotificationManager.error('يرجى التحقق من كلمة السر', '');
  }else{

 
  _agencyService.addagencywithuser(agency,user).then(res=>{
   
    getagency(1);
   console.log(res.data);
   NotificationManager.success('تم الحفظ بنجاح', '');

   localStorage.setItem("Agency", JSON.stringify(agency));
   resetagancy();
  }).catch(res=>{
    NotificationManager.error('يرجى التحقق من البينات', '');
  }

  )
}
}

function Updateagency(x){
 
  setuser({"userName":x.userName,"password":x.password,"email":x.email})
  setopenedit(true)
}
function disableuser(id){
  _usersService.disableuser(id).then(res=>{

  }
  );

}

function deleteagency(id){
  if(JSON.parse(localStorage.getItem("Agency")).id!=id){

 
  _usersService.removeuser(id).then(res=>{
   
    getagency(1);
    NotificationManager.success('تم الحذف بنجاح', '');
  }).catch(res=>{
    NotificationManager.error('يرجى التحقق من البينات', '');
  }

  )}else{

    NotificationManager.error(' لايمكن حدف الوكالة ','');
  }
}

function getagency(x){
  _usersService.getUsersandagency(x).then(res=>{
    res.data.map( x=>{
      x.createdDate=moment(x.createdDate).format('MM/DD/YYYY');
     
    }
    )
    //setAgency(res.data.agency[0]);
    setusers(res.data)
  })
 
 
}




const columns = [
 
  { field: 'userName', headerName: ' اسم المستخدم', width: 200},
  { field: 'email', headerName: ' البريد     ', width: 350 },
  

  {field: " activateuser",
  headerName: "تفعيل المستخدم",
  sortable: false,
  width: 150,
  disableClickEventBubbling: true,
  renderCell: (parm) => {
    return (
      <div>
           <Switch {...label} defaultChecked={!parm.row.disabled} size="small" onChange={e=>disableuser(parm.id)}/>
      </div>
      );
    }},
  {
    field: "actions",
    headerName: "العمليات",
    sortable: false,
    width: 150,
    disableClickEventBubbling: true,
    renderCell: (parm) => {
      return (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
       
          <IconButton className="deleteicon" aria-label="delete" size="large" onClick={e=>deleteagency(parm.id)}>
  <MdDelete    />
</IconButton>
<IconButton aria-label="delete" size="large" onClick={e=>Updateagency(parm.row)}>
  <MdEdit   />
</IconButton>

        </div>
      );
    }
  }
];

function getphotoBase64(file) {
  let document = "";
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
     
    setagency({...agency,logo:reader.result})
     
  };
  reader.onerror = function (error) {
      console.log('Error: ', error);
  };

 
}

 
useEffect(()=>{
   
  getagency(1);
 

},[])
return(
  

 
 
  <div >
 
<Grid  >
<Card variant="outlined" style={{height:"80vh"}}>
   <CardContent> 
    <div className="searchuser">
      <div style={{width:"80%"}}>
   <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center'    }}
    >
    
      <InputBase
        sx={{ ml: 1, flex: 1 }}
        placeholder="البحث عن الوكالة"
        inputProps={{ 'aria-label': ' البحث عن الوكالة' }}
      />
      <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
        <BsSearch />
      </IconButton>
     
    </Paper>
   </div>
    <Button className="addpelgrim" size="large" variant="contained" onClick={   e=>  setopenadd(true)}> 
    <span>
      <IoMdAdd/> 
      اضافة مستخدم 
       
        </span> 
        </Button>
    </div>
    <br></br>
            <DataGrid 
 density={"comfortable"}
       dir="rtl"
      localeText={arSD.components.MuiDataGrid.defaultProps.localeText} 
style={{height:"60vh"}}
        rows={users}
        columns={columns}
        rowsPerPageOptions={[5,10,100,1000]}
        
      />
      </CardContent>
 
</Card>  
 
 
  
</Grid>
<Modal
 
    
    show={openedit}
    onHide={e=>setopenedit(false)}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    dir="RTL"
   >
  <span className="tpg">معلومات المستخدم</span>
        <div className="userdiv"> 
        <FormControl >
        <TextField
     
     required
     label="  البريد الالكتلروني  "
     id="filled-basic"
     variant="outlined"
     onChange={e=>{setuser({...user,email:e.target.value})}}
     value={user.email}
     />
      </FormControl>
        </div>
  <div className="userdiv"> 

  
    <TextField
     
    required
    label=" اسم المستخدم"
    id="filled-basic"
    variant="outlined"
    onChange={e=>{setuser({...user,userName:e.target.value})}}
    value={user.userName}
    />
  
    <TextField
     
     required
     label="  كلمة المرور  "
     id="filled-basic"
     variant="outlined"
     onChange={e=>{setuser({...user,password:e.target.value})}}
     value={user.password}
     />
  </div>
  <hr></hr>
<div className="footermodal">
<Button className="save" size="large" variant="contained"  onClick={e=>updatepassword()}> حفظ</Button>
       
        <Button  className="cancel" size="large" variant="contained"  onClick={e=>resetagancy()}> الغاء</Button>
  
</div>

   </Modal>
<Modal
 
    
    show={openadd}
    onHide={e=>setopenadd(false)}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    dir="RTL"
   >
 
 <Grid   container  spacing="10"   >
    <Grid item  xs={6} sm={6} md={6}>
 
    <span className="tpg"> معلومات الوكالة</span>
 <div className="userdiv"> 
      <TextField
    
    required
    label="اسم الوكالة العربية"
    id="filled-basic"
    variant="outlined"
    onChange={e=>{setagency({...agency,agancyNameAr:e.target.value})}}
    value={agency.agancyNameAr}
    />
     <TextField
     
    required
    label=" اسم الوكالة الاجنبي  "
    id="filled-basic"
    variant="outlined"
    value={agency.agancyNameFrr}
    onChange={e=>{setagency({...agency,agancyNameFrr:e.target.value})}}
     
    />

</div>
<div className="userdiv"> 
 
   <TextField
    
    required
    label=" الهاتف  "
    id="filled-basic"
    variant="outlined"
    onChange={e=>{setagency({...agency,phone:e.target.value})}}
    value={agency.phone}
    />
    <TextField
     
    required
    label="العنوان"
    id="filled-basic"
    variant="outlined"
    onChange={e=>{setagency({...agency,adresse:e.target.value})}}
    value={agency.adresse}
    />
</div>
   <div className="userdiv">  
   <FormControl  >
          <InputLabel id="demo-simple-select-autowidth-label">الدولة  </InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={agency.country}
        
            onChange={e=>{setagency({...agency,country:e.target.value})}}
            
            label=" الدولة "
          >
           {
              countrylist.countries.country.map(x=>{
                return (<MenuItem value={x.name_country_ar} >{x.name_country_ar}</MenuItem>)
              })
   
            }
        
          </Select>
        </FormControl>
     
  <FormControl >
          <InputLabel id="demo-simple-select-autowidth-label">القنصلية  </InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={agency.city}
        
            onChange={e=>{setagency({...agency,city:e.target.value.toString()})}}
            
            label=" القنصلية  "
          >
             {
           
         
           agency.country!=null?["",embasylist.OFFLINE_EMBASSY.EMBASSY.find(x=>x.phone==countrylist.countries.country.find(x=>x.name_country_ar==agency.country).phone)].map(x=>{
                return (<MenuItem value={x.EMB_NAME_AR}>{x.EMB_NAME_AR}</MenuItem>)
              }):""
   
            }
          </Select>
        </FormControl>
        </div>
        <span className="tpg">معلومات المستخدم</span>
        <div className="userdiv"> 
        <FormControl >
        <TextField
     
     required
     label="  البريد الالكتلروني  "
     id="filled-basic"
     variant="outlined"
     onChange={e=>{setuser({...user,email:e.target.value})}}
     value={user.email}
     />
      </FormControl>
        </div>
  <div className="userdiv"> 

  
    <TextField
     
    required
    label=" اسم المستخدم"
    id="filled-basic"
    variant="outlined"
    onChange={e=>{setuser({...user,userName:e.target.value})}}
    value={user.userName}
    />
  
    <TextField
     
     required
     label="  كلمة المرور  "
     id="filled-basic"
     variant="outlined"
     onChange={e=>{setuser({...user,password:e.target.value})}}
     value={user.password}
     />
  </div>
 

        
  </Grid>
  <div class="vl"></div>
  <Grid item xs={5.9} sm={5.9} md={5.9}>
    <div style={{height:"25vh" ,display:"flex",justifyContent:"center"}}>
          <IconButton color="primary" aria-label="upload picture" component="label"  >
    <div style={{display:"felx"}}>
   <Avatar alt="Remy Sharp" src={agency.logo }style={{width:"100px",height:"100px"}}/>
   
     <input hidden accept="image/*" type="file"    onChange={e=>{ 
       
       getphotoBase64(e.target.files[0])
    
        }}/>
   
     </div>
   </IconButton> 
   </div>
   <hr></hr>
   <div className="userdiv">
   <FormControl >
          <InputLabel id="demo-simple-select-autowidth-label">الماسح الضوئي  </InputLabel>
          <Select
          rows={14}
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={agency.scanner}
        
            onChange={e=>{setagency({...agency,scanner:e.target.value})}}
            
            label=" الماسح الضوئي "
          >
                {
              scanner.scannerlist.map(x=>{
                return (<MenuItem value={x.id} >{x.name_ar}</MenuItem>)
              })
   
            }
          </Select>
        </FormControl>
   </div>

   <span className="tpg">معلومات الوكيل</span>
  <div className="userdiv"> 
 
    
        <FormControl >
          <InputLabel id="demo-simple-select-autowidth-label">الوكيل  </InputLabel>
          <Select
            labelId="demo-simple-select-autowidth-label"
            id="demo-simple-select-autowidth"
            value={agency.wakil}
        
            onChange={e=>{setagency({...agency,wakil:e.target.value})}}
            
            label=" الوكيل  "
          >
        {
              wakil.wakil.map(x=>{
                return (<MenuItem value={x.id} >{x.name_ar}</MenuItem>)
              })
   
            }
          </Select>
        </FormControl>
 </div>
 <div className="userdiv"> 
    <TextField
     
    required
    label=" اسم المستخدم"
    id="filled-basic"
    variant="outlined"
    onChange={e=>{setagency({...agency,wakilUser:e.target.value})}}
    value={agency.wakilUser}
    />
    <TextField
     
     required
     label="  كلمة المرور  "
     id="filled-basic"
     variant="outlined"
     onChange={e=>{setagency({...agency,wakilpassword:e.target.value})}}
     value={agency.wakilpassword}
     />
 
 
     
 </div>
 
 </Grid>
 
 </Grid>
 <hr></hr>
<div className="footermodal">
<Button className="save" size="large" variant="contained"  onClick={e=>addagency()}> حفظ</Button>
       
        <Button  className="cancel" size="large" variant="contained"  onClick={e=>setopenadd(false)}> الغاء</Button>
  
</div>
</Modal>
  
    
 
    </div>
 
 
)
}
 