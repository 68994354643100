import request from"../configs";

//import{authHeader}from"./auth-header";
export default class ReportingServices{

constructor(){


this.request=request;

};


getrapport(body){
let requestOptions={
headers:{
Authorization:
"bearer"+
(localStorage.getItem("user")&&
typeof localStorage.getItem("user")!="undefined"&&
JSON.parse(localStorage.getItem("user")).token),
},
responseType:'blob'
};
var mutamarlist=[];
 

var agency={
     
  "AgancyNameAr":JSON.parse(localStorage.getItem("Agency")).agancyNameAr,
  "AgancyNameFrr":JSON.parse(localStorage.getItem("Agency")).agancyNameFrr,

  "Adresse":JSON.parse(localStorage.getItem("Agency")).adresse,
  "Country":JSON.parse(localStorage.getItem("Agency")).country,
  "Phone":JSON.parse(localStorage.getItem("Agency")).phone,

  "city":JSON.parse(localStorage.getItem("Agency")).city,

 "Logo":JSON.parse(localStorage.getItem("Agency")).logo[0].url,
}
body.mutamarsList.map(x=>{
debugger
var x2={
"Title":x.fields.title?x.fields.title:"",

"Gender":x.fields.gender?x.fields.gender:"",
//name
"FirstName":x.fields.firstName?x.fields.firstName:"",
"LastName":x.fields.lastName?x.fields.lastName:"",
"FatherName":x.fields.fatherName?x.fields.fatherName:"",
"GrandFatherName":x.fields.grandFatherName?x.fields.grandFatherName:"",
//arabicname
"ArabicName":x.fields.arabicName?x.fields.arabicName:"",
"ArabicFatherName":x.fields.arabicFatherName?x.fields.arabicFatherName:"",
"ArabicGrandfatherName":x.fields.arabicGrandfatherName?x.fields.arabicGrandfatherName:"",
"ArabicLastName":x.fields.arabicLastName?x.fields.arabicLastName:"",
//passport
"MRZ":x.fields.mrz?x.fields.mrz:"",
"PassportNumber":x.fields.passportNumber?x.fields.passportNumber:"",
"PassportDelieveryDate":x.fields.passportDelieveryDate?x.fields.passportDelieveryDate:"",
"PassportExpiryDate":x.fields.passportExpiryDate?x.fields.passportExpiryDate:"",
"PassportDeliveryCity":x.fields.passportDeliveryCity?x.fields.passportDeliveryCity:"",
"PassportType":x.fields.passportType?x.fields.passportType:"",
"PassportDelieveryCity":x.fields.passportDelieveryCity?x.fields.passportDelieveryCity:"",
"PassportDeliveryCountry":x.fields.passportDeliveryCountry?x.fields.passportDeliveryCountry:"",

"NationalityCountry":x.fields.nationalityCountry?x.fields.nationalityCountry:"",

"CIN":x.fields.cIN?x.fields.cIN:"",

"BirthDate":x.fields.birthDate?x.fields.birthDate:"",
"BirthCountry":x.fields.birthCountry?x.fields.birthCountry:"",

"BirthCity":x.fields.birthCity?x.fields.birthCity:"",
//education&job&martialstatus
"Job":x.fields.job?x.fields.job:"",
"CivilState":x.fields.civilState?x.fields.civilState:"",
"EducationLevel":x.fields.educationLevel?x.fields.educationLevel:"",

//health
"HealthStatus":x.fields.healthStatus?x.fields.healthStatus:"",

"HealthDescription":x.fields.HealthDescription?x.fields.HealthDescription:"",
//muhram
"HasMuhram":true,
"MahramId":0,
"AddressCountryId":0,

"AddressCity":x.fields.addressCity?x.fields.addressCity:"",
"Tel":x.fields.tel?x.fields.tel:"",
"AddressZipCode":x.fields.addressZipCode?x.fields.addressZipCode:"",
"AddressStreet":x.fields.AddressStreet?x.fields.AddressStreet:"",
"Email":x.fields.Email?x.fields.Email:"",
"Transport":x.fields.transport?x.fields.transport:"",
"WayPort":x.fields.wayPort?x.fields.wayPort:"",
"DateArrival":x.fields.dateArrival?x.fields.dateArrival:"",
"StayPeriod":24,
"FlightNumber":x.fields.flightNumber?x.fields.flightNumber:"",
"Destination":x.fields.destination?x.fields.destination:"",
//pictures
"PilgrimPicturePath":x.fields.pilgrimPicturePath?x.fields.pilgrimPicturePath[0].url:"",
"PassportPicturePath":x.fields.passportPicturePath?x.fields.passportPicturePath[0].url:"",
"Notes":"",
"roupingId":0,
"PilgrimCode":0,
"VisapicPath":x.fields.visapicPath?x.fields.visapicPath[0].url:"",
"visaNumber":x.fields.visaNumber?x.fields.visaNumber:"",
"wakilNumber":x.fields.wakilNumber?x.fields.wakilNumber:"",
"Nquettance":"",
"MontantTotal":"",
"payment":"",
"avance":"",

"resID":"",
"resdateissue":"",
"resdateexpyer":"",
"respicturepath":"",
"roomtype":"",
"frommobile":true,

}
mutamarlist.push(x2)

})

body.mutamarsList=mutamarlist;
body.Agency=agency;
return this.request.post("http://reporting.nusuk.tn/api/Raporting/getRapport",body,requestOptions);



}



}