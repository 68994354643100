import request from "../configs";

// import { authHeader } from "./auth-header";
export default class UsersService {
 
    constructor() {
       
    
      this.request = request;
     
      };
   
   
      getUsers(filter) {
      let requestOptions =  {
        headers: {
          Authorization:
            "bearer " +
            (localStorage.getItem("user") &&
              typeof localStorage.getItem("user") != "undefined" &&
              JSON.parse(localStorage.getItem("user")).token),
        }
      };
       

 
      return this.request.get(`/api/Users`,requestOptions);
    }
    
    getUsersandagency(filter) {
      let requestOptions =  {
        headers: {
          Authorization:
            "bearer " +
            (localStorage.getItem("user") &&
              typeof localStorage.getItem("user") != "undefined" &&
              JSON.parse(localStorage.getItem("user")).token),
        }
      };
       

 
      return this.request.get(`/api/Accounts/getAll`,requestOptions);
    }
    disableuser(id){
      let requestOptions =  {
        headers: {
          Authorization:
            "bearer " +
            (localStorage.getItem("user") &&
              typeof localStorage.getItem("user") != "undefined" &&
              JSON.parse(localStorage.getItem("user")).token),
        }
      };
       
 
      return this.request.get(`/api/Accounts/disableuser/`+id,requestOptions);
    }
    removeuser(id){
      let requestOptions =  {
        headers: {
          Authorization:
            "bearer " +
            (localStorage.getItem("user") &&
              typeof localStorage.getItem("user") != "undefined" &&
              JSON.parse(localStorage.getItem("user")).token),
        }
      };
       
 
      return this.request.get(`/api/Accounts/removeuser/`+id,requestOptions);
    }
    getUsersbyid(id) {
      let requestOptions =  {
        headers: {
          Authorization:
            "bearer " +
            (localStorage.getItem("user") &&
              typeof localStorage.getItem("user") != "undefined" &&
              JSON.parse(localStorage.getItem("user")).token),
        }
      };
       
 
      return this.request.get(`/api/Users`+id,requestOptions);
    }
    deleteUsers(id) {
      let requestOptions =  {
        headers: {
          Authorization:
            "bearer " +
            (localStorage.getItem("user") &&
              typeof localStorage.getItem("user") != "undefined" &&
              JSON.parse(localStorage.getItem("user")).token),
        }
      };
       
 
      return this.request.delete(`/api/Users/`+id,requestOptions);
    }

   
          
    addUsers(body){
      let requestOptions =  {
        headers: {
          Authorization:
            "bearer " +
            (localStorage.getItem("user") &&
              typeof localStorage.getItem("user") != "undefined" &&
              JSON.parse(localStorage.getItem("user")).token),
        }
      };
 
      return this.request.post("/api/Users",body,requestOptions);
    }
        
    updatepassword(body){
      let requestOptions =  {
        headers: {
          Authorization:
            "bearer " +
            (localStorage.getItem("user") &&
              typeof localStorage.getItem("user") != "undefined" &&
              JSON.parse(localStorage.getItem("user")).token),
        }
      };
 
      return this.request.post("/api/Auth/changePassword/",body,requestOptions);
    }
 
   
  }