import { Box, Button, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
  
import senderimage from "./friends_link_send_share_icon_123609.ico"
import scannerimage from "./scannerlogo.png"
import applogo from "./appplogo.jpg"
 

export default function Download({ ...props }) {
 
return(
  

 
 
  <div style={{display:"flex",flexDirection:"row"}}>
<Box sx={{width: "350px" ,margin:"5px"}}>
    <Card variant="outlined">
    <CardContent style={{textAlign:"center"}}>
        <Typography sx={{ fontSize: 14 ,fontWeight:"bolder" ,textAlign:"center"}}   gutterBottom>
         برنامج الارسال الي الوكيل
      </Typography>
      <img src={senderimage}   height= "200px"></img>
      </CardContent>
      
        <CardActions style={{textAlign:"center"}}>

        <Button onClick={e=>window.open("http://www.awslabs.tn/UPLOAD/NusukSender.exe" )}>تحميل</Button>

        </CardActions>

    </Card>
    </Box>
    <Box sx={{width: "350px",margin:"5px" }}>
    <Card variant="outlined">
    <CardContent style={{textAlign:"center"}}>
        <Typography sx={{ fontSize: 14 ,fontWeight:"bolder" ,textAlign:"center"}}   gutterBottom>
         برنامج  الماسح الالي
      </Typography>
      <img src={scannerimage}   height= "200px"></img>
      </CardContent>
      
        <CardActions style={{textAlign:"center"}}>

        <Button onClick={e=>window.open("http://www.awslabs.tn/UPLOAD/NusukScanner.exe" )}>تحميل</Button>

        </CardActions>

    </Card>
    </Box>
   { /*<Box sx={{width: "350px",margin:"5px" }}>
    <Card variant="outlined">
    <CardContent style={{textAlign:"center"}}>
        <Typography sx={{ fontSize: 14 ,fontWeight:"bolder" ,textAlign:"center"}}   gutterBottom>
         برنامج  الهاتف الجوال  
      </Typography>
      <img src={applogo}      height= "200px"></img>
      </CardContent>
      
        <CardActions style={{textAlign:"center"}}>

        <Button>تحميل</Button>

        </CardActions>

    </Card>
    </Box>*/}
    </div>
 
 
)
}
 